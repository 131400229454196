export const R2_2_Summary = 'This is a partial release of the R2 API collection that OWL previously communicated as a singular release. We have broken this collection of APIs down into 3 separate releases to ensure features are published in priority order as early as possible.';

export const R2_2_Features = [
    'Camera Telemetry API: new fields added: vin, networkQuality, canOdometer, canFuelPercentage, canEngineRuntime, canEngineRpm, derivedOdometer, derivedRuntime, derivedFuelEconomy.',
    'Camera Telemetry Webhook: “r2” version added with new fields: vin, networkQuality,canOdometer, canFuelPercentage, canEngineRuntime, canEngineRpm, derivedOdometer, derivedRuntime, derivedFuelEconomy, eventVersion. ',
    'Camera Mode Webhook: “r2” version added with new field: eventVersion.',
    'Camera Trips API: “r2” version added. Fleet Managers can now view trips that the driver has taken, with telemetry data updated every 6 seconds and with bearing changes.',
    'Camera Trips Webhook: “r2” version added.',
    'Camera Clips/Camera API: new query parameters for dateTo, title, and type.'
]
export const R2_2_CompatibilityIssues = [

]
export const R2_2_Bugfixes = [

]
