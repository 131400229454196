export const R2_10_12_21_Summary = 'This release includes the new /state and /event webhooks, robust device configuration for device messages, and improvements or additions to existing features.';

export const R2_10_12_21_Features = [
    'Camera State Webhook: "r2" version added. Fleet managers can now receive updates for camera state events, which include active trip, ignitionOn status, and more. See <a href="https://portal.partner.owlcam.com/events-api#operations-tag-state">Webhooks</a> and <a href="https://portal.partner.owlcam.com/definitions#state">Definitions</a> for more.',
    'Camera Event Webhook: "r2" version added. Fleet managers can now receive instant updates for camera clip events. See <a href="https://portal.partner.owlcam.com/events-api#operations-tag-event">Webhooks</a> for more.',
    'Camera Clips and Events: Xirgocam will start reporting ADAS tailgating and lane-departure events.',
    'Camera Trips Webhook: Fleet managers now receive instant updates for tripEnd events. See <a href="https://portal.partner.owlcam.com/events-api#operations-tag-trip">Webhooks</a> for more.',
    'Synchronous Camera Events: Fleet managers can now opt in to synchronous camera updates using the eventQueueSize setting. See Settings Release 1 for more.'
]
export const R2_10_12_21_CompatibilityIssues = [

]
export const R2_10_12_21_Bugfixes = [
    'Xirgocam no longer goes into guard mode during long OK Presto clips.',
    'Trailing question marks have been removed from most clip titles.', 
    'The motionAlerts setting is now being respected.',
    'Xirgocam no longer takes 1 second outside camera clips when waking from guard mode.',
    'Sending a pause message during history video playback now properly pauses the stream.',
    'Periodic playing messages are sent during history playback.'
]

export const R2_10_12_21_KnownIssues = [
    'Occasional video stuttering on the internal display.'
];
