export const R2_3_Summary = 'This is a partial release of the R2 API collection that OWL previously communicated as a singular release. We have broken this collection of APIs down into 3 separate releases to ensure features are published in priority order as early as possible.';

export const R2_3_Features = [
    'Camera Diagnostics API: “r2” version added. Fleet Managers can now view DTC information for the vehicles their Xirgocams are installed in.', 
    'Camera Diagnostics Webhook: “r2” version added.',
    'Camera API: “version” field added. Fleet managers can now view the firmware versions of their Xirgocams.',
    'Camera Activation Webhook: “r2” version added.'
]
export const R2_3_CompatibilityIssues = [

]
export const R2_3_Bugfixes = [

]
