import React from "react";
import ReleaseNoteSection from "../app/components/ReleaseNotes/ReleaseNoteSection";
import {
  R2_1_Summary,
  R2_1_Features,
  R2_1_CompatibilityIssues,
  R2_1_Bugfixes,
} from "../versionContent/r2/releaseNotes/release-1";
import {
  R2_2_Summary,
  R2_2_Features,
} from "../versionContent/r2/releaseNotes/release-2";
import {
  R2_3_Summary,
  R2_3_Features,
} from "../versionContent/r2/releaseNotes/release-3";
import {
  R2_10_12_21_Summary,
  R2_10_12_21_Features,
  R2_10_12_21_Bugfixes,
  R2_10_12_21_KnownIssues,
} from "../versionContent/r2/releaseNotes/release_101221";
import {
  R2_11_23_21_Summary,
  R2_11_23_21_Features,
  R2_11_23_21_Bugfixes,
  R2_11_23_21_KnownIssues,
} from "../versionContent/r2/releaseNotes/release_112321";
import {
  R2_1_11_22_Summary,
  R2_1_11_22_Features,
  R2_1_11_22_Bugfixes,
  R2_1_11_22_KnownIssues,
} from "../versionContent/r2/releaseNotes/release_11122";
import {
  R2_3_04_22_Summary,
  R2_3_04_22_Features,
  R2_3_04_22_Bugfixes,
  R2_3_04_22_KnownIssues,
} from "../versionContent/r2/releaseNotes/release_030422";
import {
  R2_4_08_22_Summary,
  R2_4_08_22_Bugfixes,
  R2_4_08_22_KnownIssues,
} from "../versionContent/r2/releaseNotes/release_040822";
import {
  R2_5_3_22_Summary,
  R2_5_3_22_Features,
  R2_5_3_22_Bugfixes,
  R2_5_3_22_KnownIssues,
} from "../versionContent/r2/releaseNotes/release_050322";
import {
  R2_5_27_22_ReleaseSummary,
  R2_5_27_22_Features,
  R2_5_27_22_Bugfixes,
  R2_5_27_22_CompatibilityIssues,
  R2_5_27_22_KnownIssues,
} from "../versionContent/r2/releaseNotes/release_052722";
import {
  R2_6_28_22_ReleaseSummary,
  R2_6_28_22_Features,
  R2_6_28_22_Bugfixes,
  R2_6_28_22_CompatibilityIssues,
  R2_6_28_22_KnownIssues,
  R2_6_28_22_Improvements,
} from "../versionContent/r2/releaseNotes/release_062822";
import {
  R2_7_19_22_ReleaseSummary,
  R2_7_19_22_Features,
  R2_7_19_22_Bugfixes,
  R2_7_19_22_CompatibilityIssues,
  R2_7_19_22_KnownIssues,
  R2_7_19_22_Improvements,
} from "../versionContent/r2/releaseNotes/release_071922";
import {
  R2_9_16_22_ReleaseSummary,
  R2_9_16_22_Features,
  R2_9_16_22_Bugfixes,
  R2_9_16_22_CompatibilityIssues,
  R2_9_16_22_KnownIssues,
  R2_9_16_22_Improvements,
} from "../versionContent/r2/releaseNotes/release_091622";
import {
  R2_5_24_23_ReleaseSummary,
  R2_5_24_23_Features,
  R2_5_24_23_Bugfixes,
  R2_5_24_23_CompatibilityIssues,
  R2_5_24_23_KnownIssues,
  R2_5_24_23_Improvements,
} from "../versionContent/r2/releaseNotes/release_052423";
import {
  R2_9_07_23_ReleaseSummary,
  R2_9_07_23_Features,
  R2_9_07_23_CompatibilityIssues,
  R2_9_07_23_Improvements,
  R2_9_07_23_IssuesAddressed,
  R2_9_07_23_Errata,
  R2_9_07_23_KnownIssues,
} from "../versionContent/r2/releaseNotes/release_090723";
import {
  R2_9_14_23_ReleaseSummary,
  R2_9_14_23_Features,
  R2_9_14_23_CompatibilityIssues,
  R2_9_14_23_Improvements,
  R2_9_14_23_IssuesAddressed,
  R2_9_14_23_Errata,
  R2_9_14_23_KnownIssues,
} from "../versionContent/r2/releaseNotes/release_091423";
import {
  R2_12_01_23_ReleaseSummary,
  R2_12_01_23_Features,
  R2_12_01_23_CompatibilityIssues,
  R2_12_01_23_Improvements,
  R2_12_01_23_IssuesAddressed,
  R2_12_01_23_Errata,
  R2_12_01_23_KnownIssues,
} from "../versionContent/r2/releaseNotes/release_120123";
import {
  R2_03_11_24_ReleaseSummary,
  R2_03_11_24_Features,
  R2_03_11_24_CompatibilityIssues,
  R2_03_11_24_Improvements,
  R2_03_11_24_IssuesAddressed,
  R2_03_11_24_Errata,
  R2_03_11_24_KnownIssues,
} from "../versionContent/r2/releaseNotes/release_031124";
import { R2_09_19_24_ReleaseSummary } from "../versionContent/r2/releaseNotes/release_091924";

import {
  OS_1_Summary,
  OS_1_Features,
  OS_1_CompatibilityIssues,
} from "../versionContent/settings-release1/releaseNotes/release-1";
import {
  OS_1_1_Summary,
  OS_1_1_Features,
  OS_1_1_CompatibilityIssues,
  OS_1_1_Bugfixes,
} from "../versionContent/settings-release1/releaseNotes/release-1.1";

const ReleaseNotes = () => {
  return (
    <div className="container-fluid">
      <div className="flex-xl-nowrap row">
        <div className="col-xl-2 col-md-3 col-12 d-flex flex-column sidenav sidenavitem">
          <a href="#v1">Releases</a>
          <ul>
            <li>
              <a href="#r2-091924">R2 9/19</a>
            </li>
            <li>
              <a href="#r2-031124">R2 3/11</a>
            </li>
            <li>
              <a href="#r2-120123">R2 12/01</a>
            </li>
            <li>
              <a href="#r2-91423">R2 9/14</a>
            </li>
            <li>
              <a href="#r2-90723">R2 9/07</a>
            </li>
            <li>
              <a href="#r2-52423">R2 5/23</a>
            </li>
            <li>
              <a href="#r2-91622">R2 9/16</a>
            </li>
            <li>
              <a href="#r2-71922">R2 7/19</a>
            </li>
            <li>
              <a href="#r2-62822">R2 6/28</a>
            </li>
            <li>
              <a href="#r2-52722">R2 5/27</a>
            </li>
            <li>
              <a href="#r2-50322">R2 5/3</a>
            </li>
            <li>
              <a href="#r2-40822">R2 4/8</a>
            </li>
            <li>
              <a href="#r2-30422">R2 3/4</a>
            </li>
            <li>
              <a href="#r2-11122">R2 1/11</a>
            </li>
            <li>
              <a href="#r2-112321">R2 11/23</a>
            </li>
            <li>
              <a href="#r2-101221">R2 10/12</a>
            </li>
            <li>
              <a href="#r2-3">R2 Release 3</a>
            </li>
            <li>
              <a href="#os-1-1">OS Release 1.1</a>
            </li>
            <li>
              <a href="#os-1">OS Release 1</a>
            </li>
            <li>
              <a href="#r2-2">R2 Release 2</a>
            </li>
            <li>
              <a href="#r2-1">R2 Release 1</a>
            </li>
            <li>
              <a href="#v1">R1</a>
            </li>
          </ul>
        </div>

        <main
          className="col-12 col-md-9 col-xl-10 py-md-3 pl-md-5 bd-content"
          role="main"
          id="main-definitions"
        >
          <h1 className="pagetitle" style={{ paddingTop: "32px" }}>
            Release Notes
          </h1>
          <p className="bd-lead">Last updated: September 19th, 2024</p>

          <p>Older releases shall be supported unless otherwise specified.</p>
          <ReleaseNoteSection
            isLatest={true}
            id={"r2-091924"}
            releaseName={"R2 33.2.1"}
            releaseDate={"September 19th, 2024"}
            summary={R2_09_19_24_ReleaseSummary}
          />
          <ReleaseNoteSection
            isLatest={false}
            id={"r2-031124"}
            releaseName={"R2 33.1"}
            releaseDate={"March 11th, 2024"}
            summary={R2_03_11_24_ReleaseSummary}
            newFeatures={R2_03_11_24_Features}
            issuesAddressed={R2_03_11_24_IssuesAddressed}
            errata={R2_03_11_24_Errata}
            knownIssues={R2_03_11_24_KnownIssues}
            backcompatIssues={R2_03_11_24_CompatibilityIssues}
            improvements={R2_03_11_24_Improvements}
          />
          <ReleaseNoteSection
            isLatest={false}
            id={"r2-120123"}
            releaseName={"R2 12/01"}
            releaseDate={"December 1st, 2023"}
            summary={R2_12_01_23_ReleaseSummary}
            newFeatures={R2_12_01_23_Features}
            issuesAddressed={R2_12_01_23_IssuesAddressed}
            errata={R2_12_01_23_Errata}
            knownIssues={R2_12_01_23_KnownIssues}
            backcompatIssues={R2_12_01_23_CompatibilityIssues}
            improvements={R2_12_01_23_Improvements}
          />
          <ReleaseNoteSection
            isLatest={false}
            id={"r2-91423"}
            releaseName={"R2 9/14"}
            releaseDate={"September 14th, 2023"}
            summary={R2_9_14_23_ReleaseSummary}
            newFeatures={R2_9_14_23_Features}
            issuesAddressed={R2_9_14_23_IssuesAddressed}
            errata={R2_9_14_23_Errata}
            knownIssues={R2_9_14_23_KnownIssues}
            backcompatIssues={R2_9_14_23_CompatibilityIssues}
            improvements={R2_9_14_23_Improvements}
          />
          <ReleaseNoteSection
            isLatest={false}
            id={"r2-90723"}
            releaseName={"R2 9/07"}
            releaseDate={"September 7th, 2023"}
            summary={R2_9_07_23_ReleaseSummary}
            newFeatures={R2_9_07_23_Features}
            issuesAddressed={R2_9_07_23_IssuesAddressed}
            errata={R2_9_07_23_Errata}
            knownIssues={R2_9_07_23_KnownIssues}
            backcompatIssues={R2_9_07_23_CompatibilityIssues}
            improvements={R2_9_07_23_Improvements}
          />
          <ReleaseNoteSection
            isLatest={false}
            id={"r2-52423"}
            releaseName={"R2 5/23"}
            releaseDate={"May 24th, 2023"}
            summary={R2_5_24_23_ReleaseSummary}
            newFeatures={R2_5_24_23_Features}
            issuesAddressed={R2_5_24_23_Bugfixes}
            knownIssues={R2_5_24_23_KnownIssues}
            backcompatIssues={R2_5_24_23_CompatibilityIssues}
            improvements={R2_5_24_23_Improvements}
          />
          <ReleaseNoteSection
            isLatest={false}
            id={"r2-91622"}
            releaseName={"R2 9/16"}
            releaseDate={"September 16th, 2022"}
            summary={R2_9_16_22_ReleaseSummary}
            newFeatures={R2_9_16_22_Features}
            issuesAddressed={R2_9_16_22_Bugfixes}
            knownIssues={R2_9_16_22_KnownIssues}
            backcompatIssues={R2_9_16_22_CompatibilityIssues}
            improvements={R2_9_16_22_Improvements}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-71922"}
            releaseName={"R2 7/19"}
            releaseDate={"July 19th, 2022"}
            summary={R2_7_19_22_ReleaseSummary}
            newFeatures={R2_7_19_22_Features}
            issuesAddressed={R2_7_19_22_Bugfixes}
            knownIssues={R2_7_19_22_KnownIssues}
            backcompatIssues={R2_7_19_22_CompatibilityIssues}
            improvements={R2_7_19_22_Improvements}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-62822"}
            releaseName={"R2 6/28"}
            releaseDate={"June 28th, 2022"}
            summary={R2_6_28_22_ReleaseSummary}
            newFeatures={R2_6_28_22_Features}
            issuesAddressed={R2_6_28_22_Bugfixes}
            knownIssues={R2_6_28_22_KnownIssues}
            backcompatIssues={R2_6_28_22_CompatibilityIssues}
            improvements={R2_6_28_22_Improvements}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-52722"}
            releaseName={"R2 5/27"}
            releaseDate={"May 27th, 2022"}
            summary={R2_5_27_22_ReleaseSummary}
            newFeatures={R2_5_27_22_Features}
            issuesAddressed={R2_5_27_22_Bugfixes}
            knownIssues={R2_5_27_22_KnownIssues}
            backcompatIssues={R2_5_27_22_CompatibilityIssues}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-50322"}
            releaseName={"R2 5/3"}
            releaseDate={"May 3rd, 2022"}
            summary={R2_5_3_22_Summary}
            newFeatures={R2_5_3_22_Features}
            issuesAddressed={R2_5_3_22_Bugfixes}
            knownIssues={R2_5_3_22_KnownIssues}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-40822"}
            releaseName={"R2 4/8"}
            releaseDate={"April 8th, 2022"}
            summary={R2_4_08_22_Summary}
            issuesAddressed={R2_4_08_22_Bugfixes}
            knownIssues={R2_4_08_22_KnownIssues}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-30422"}
            releaseName={"R2 3/4"}
            releaseDate={"March 3rd, 2022"}
            summary={R2_3_04_22_Summary}
            newFeatures={R2_3_04_22_Features}
            issuesAddressed={R2_3_04_22_Bugfixes}
            knownIssues={R2_3_04_22_KnownIssues}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-11122"}
            releaseName={"R2 1/11"}
            releaseDate={"January 11th, 2022"}
            summary={R2_1_11_22_Summary}
            newFeatures={R2_1_11_22_Features}
            issuesAddressed={R2_1_11_22_Bugfixes}
            knownIssues={R2_1_11_22_KnownIssues}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-112321"}
            releaseName={"R2 11/23"}
            releaseDate={"November 23rd, 2021"}
            summary={R2_11_23_21_Summary}
            newFeatures={R2_11_23_21_Features}
            issuesAddressed={R2_11_23_21_Bugfixes}
            knownIssues={R2_11_23_21_KnownIssues}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-101221"}
            releaseName={"R2 10/12"}
            releaseDate={"October 12th, 2021"}
            summary={R2_10_12_21_Summary}
            newFeatures={R2_10_12_21_Features}
            issuesAddressed={R2_10_12_21_Bugfixes}
            knownIssues={R2_10_12_21_KnownIssues}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-3"}
            releaseName={"R2 API Release 3"}
            releaseDate={"TBD"}
            summary={R2_3_Summary}
            newFeatures={R2_3_Features}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"os-1-1"}
            releaseName={"OS API Release 1.1"}
            releaseDate={"June 9, 2021"}
            summary={OS_1_1_Summary}
            newFeatures={OS_1_1_Features}
            backcompatIssues={OS_1_1_CompatibilityIssues}
            issuesAddressed={OS_1_1_Bugfixes}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"os-1"}
            releaseName={"OS API Release 1"}
            releaseDate={"May 5, 2021"}
            summary={OS_1_Summary}
            newFeatures={OS_1_Features}
            backcompatIssues={OS_1_CompatibilityIssues}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-2"}
            releaseName={"R2 API Release 2"}
            releaseDate={"April 14, 2021"}
            summary={R2_2_Summary}
            newFeatures={R2_2_Features}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"r2-1"}
            releaseName={"R2 API Release 1"}
            releaseDate={"January 5, 2021"}
            summary={R2_1_Summary}
            newFeatures={R2_1_Features}
            backcompatIssues={R2_1_CompatibilityIssues}
            issuesAddressed={R2_1_Bugfixes}
          />

          <ReleaseNoteSection
            isLatest={false}
            id={"v1"}
            releaseName={"R1"}
            releaseDate={"April 4, 2019"}
            summary={
              "R1 is the initial release of the Xirgocam external-facing APIs"
            }
            newFeatures={[
              "Xirgocam API - partner facing cloud to Xirgocam cloud data access",
              "Camera API - direct access to Xirgocam for live and history streaming ",
              "Events Webhooks - callbacks to partner cloud for realtime events",
              "Live view functionality",
              "History functionality",
              "Clips functionality",
            ]}
          />
        </main>
      </div>
    </div>
  );
};

export default ReleaseNotes;
