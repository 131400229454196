// REACT + ROUTER
import React from 'react';

// BOOTSTRAP COMPONENTS
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { useHistory } from 'react-router-dom';

export const PAGES = {
  xirgocamAPI: {
    title: 'XirgocamAPI',
    path: '/xirgocam-api',
  },
  cameraAPI: {
    title: 'CameraAPI',
    path: '/camera-api',
  },
  settingsAPI: {
    title: 'SettingsAPI',
    path: '/settings-api',
  },
  webhooks: {
    title: 'Webhooks',
    path: '/events-api',
  },
  adminAPI: {
    title: 'AdminAPI',
    path: '/admin-api',
  },
  definitions: {
    title: 'Definitions',
    path: '/definitions',
  },
  releaseNotes: {
    title: 'Release Notes',
    path: '/release-notes',
  },
};

export const EXTERNAL_RELEASES = [
  {
    name: 'R1',
    tabs: [PAGES.xirgocamAPI, PAGES.cameraAPI, PAGES.webhooks, PAGES.definitions, PAGES.releaseNotes],
  },
  {
    name: 'R2',
    tabs: [PAGES.xirgocamAPI, PAGES.cameraAPI, PAGES.webhooks, PAGES.definitions, PAGES.releaseNotes],
  },
  {
    name: 'Settings Release 1',
    tabs: [PAGES.settingsAPI, PAGES.definitions, PAGES.releaseNotes],
  },
  {
    name: 'Admin Release 1',
    tabs: [PAGES.adminAPI, PAGES.definitions, PAGES.releaseNotes],
  },
  {
    name: 'Roadmap',
    tabs: [PAGES.xirgocamAPI, PAGES.cameraAPI, PAGES.settingsAPI, PAGES.adminAPI, PAGES.webhooks, PAGES.definitions, PAGES.releaseNotes],
  },
];

function NavbarVersionControl({ version, handleSelectVersion, userEmail, currentPath }) {
  const history = useHistory();

  // console.log(version);
  const currentRelease = EXTERNAL_RELEASES.find(release => release.name === version);

  // console.log('External releases');
  // console.log(currentRelease);
  // console.log(currentPath);
  if (currentRelease) {
    const tabExistsUnderSelectedVersion = currentRelease.tabs.findIndex(tab => tab.path === currentPath) >= 0;
    if (!tabExistsUnderSelectedVersion && currentPath !== '/') {
      history.push(currentRelease.tabs[0].path);
    }
  }

  return (
    <React.Fragment>
      <Navbar.Text style={{ color: '#FFF' }}>Release</Navbar.Text>
      <NavDropdown title={`${version}`} id='nav-version-dropdown' onSelect={handleSelectVersion}>
        {EXTERNAL_RELEASES.map((version, i) => {
          return (
            <NavDropdown.Item eventKey={version.name} key={version.name}>
              {version.name}
            </NavDropdown.Item>
          );
        })}
      </NavDropdown>
    </React.Fragment>
  );
}

export default NavbarVersionControl;
