// REACT + ROUTER
import React from 'react';
import { Link } from 'react-router-dom';

// BOOTSTRAP COMPONENTS

// STYLES
import '../../styles/components/Navbar.styles.css';

// RELEASES
import { EXTERNAL_RELEASES } from './NavbarVersionControl';

function NavbarLink({ title, path, currentPath, seperatorAfter }) {
  let className = path === currentPath ? 'navbar-link active' : 'navbar-link';
  let style = seperatorAfter ? { borderRight: '5px solid #999' } : {};

  return (
    <Link style={style} to={path} className={className}>
      {title}
    </Link>
  );
}

function NavbarLinks({ currentPath, version }) {
  return (
    <React.Fragment>
      {EXTERNAL_RELEASES.find(release => release.name === version).tabs.map(tab => {
        return <NavbarLink key={tab.title} title={tab.title} path={tab.path} currentPath={currentPath} />;
      })}
    </React.Fragment>
  );
}

export default NavbarLinks;
