export const R2_5_3_22_Summary = [
  "This release is a followup to R32.6.1 that enables customers to set the ignition on voltage thresholds via the API.  This is to address vehicles in the field who may have non-standard batteries."
];

export const R2_5_3_22_Features = [
  'Settings for ignition voltage levels have been introduced.  Default is 13.05 with no deadband.  See <a href="/definitions#trips">Definitions</a>'
];

export const R2_5_3_22_CompatibilityIssues = [];

export const R2_5_3_22_Bugfixes = [
  "Fixed an issue where /trips endpoint messages could be corrupted.",
  "Fixed an issue where inside video clips could not be downloaded from the journal",
];

export const R2_5_3_22_KnownIssues = [
  "ADAS features will be disabled during live view sessions.",
  "Audio setting takes some time to take effect (< 30 seconds).",
  "Occasionally video on screen has slight lag.",
  "Devices not mounted in a vehicle may trigger aggressive driving events on startup.",
  "Diagnostic Trouble Codes are corrupted if more than 10 are received from the vehicle.",
  "Forward Collision Warning is overly sensitive.  It is recommended it is not enabled at this time.",
  "Occasionally, red 'Recording' dot on camera display will be there indefinitely after an 'OK Presto' clip is made.",
  "After deactivating a camera and then re-activating it, camera will use previous settings until the next reboot.",
  "Occasionally, sliding 'up' on the screen will not turn on the inside camera and the 'outside' camera view will be stretched poorly.  Fix is to swipe down, then up again.",
  "When changing the resolution of the outside camera, the camera needs to restart (either a reboot or going into guard mode/exiting) before the new resolution will take effect.",
  "When the device has no GPS position, it will report the last known accurate location for events until it receives a new location.",
  "Events and Telemetry/State use two different sources for position, leading to inconsistencies in reported location.",
  "When large amounts of clips are generated, media will not be available until all media completes uploading.",
  "If a yellow alert (bump) escalates to a red alert (glassbreak), only the red alert will upload media."
];
