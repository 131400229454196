export const R2_5_27_22_ReleaseSummary = [
    "Release Version: 32.6.3. ",
    "This release is a followup to R32.6.2 to address an Out Of Memory issue causing devices to repeatedly reboot."
  ];
  
  export const R2_5_27_22_Features = [];
  
  export const R2_5_27_22_CompatibilityIssues = [];
  
  export const R2_5_27_22_Bugfixes = [
    "Fixed an Out Of Memory error causing devices to become stuck in a reboot loop.",
  ];
  
  export const R2_5_27_22_KnownIssues = [
    "ADAS features will be disabled during live view sessions. [XIR-810]",
    "Audio setting takes some time to take effect (< 30 seconds). [XIR-811]",
    "Occasionally video on screen has slight lag. [XIR-541]",
    "Devices not mounted in a vehicle may trigger aggressive driving events on startup. [XIR-589]",
    "Diagnostic Trouble Codes are corrupted if more than 10 are received from the vehicle. [XIR-625]",
    "Forward Collision Warning is overly sensitive.  It is recommended it is not enabled at this time. [XIR-746]",
    "Occasionally, red 'Recording' dot on camera display will be there indefinitely after an 'OK Presto' clip is made. [XIR-582]",
    "After deactivating a camera and then re-activating it, camera will use previous settings until the next reboot. [XIR-693]",
    "Occasionally, sliding 'up' on the screen will not turn on the inside camera and the 'outside' camera view will be stretched poorly.  Fix is to swipe down, then up again. [XIR-530]",
    "When changing the resolution of the outside camera, the camera needs to restart (either a reboot or going into guard mode/exiting) before the new resolution will take effect. [XIR-816]",
    "Events and Telemetry/State use two different sources for position, leading to inconsistencies in reported location. [XIR-707]",
    "When large amounts of clips are generated, media will not be available until all media completes uploading. [XIR-677]",
    "If a yellow alert (bump) escalates to a red alert (glassbreak), only the red alert will upload media. [XIR-761]",
  ];