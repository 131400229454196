import React from 'react'
import { tableContentSelector } from '../../../utils/tableContentSelector'
import { scrollToElementById } from '../../../utils/ScrollUtility';
import { Link } from "react-router-dom";

const TableContentList = ({ version, name }) => {
  const contentList = tableContentSelector(version, name);

  const handleClick = (itemId) => {
    scrollToElementById(itemId);
  }
  
  return (
    <div className="col-xl-2 col-md-3 col-12 d-flex flex-column sidenav sidenavitem">
      {contentList.map(listItem => {
        return (
        <Link to={{ hash: listItem.id }} key={listItem.id} onClick={() => handleClick(listItem.id)}>{listItem.name}</Link>
        )
      })}
    </div>
  )
}

export default TableContentList;