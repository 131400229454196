export const R2_4_08_22_Summary = [
  "This release is a hotfix to R32.6 to address issues with derivedOdometer, Day/Night Sensitivity, and timezones on XT8890 cameras."
];

export const R2_4_08_22_CompatibilityIssues = [];

export const R2_4_08_22_Bugfixes = [
  "derivedOdometer and derivedRuntime should now reflect the true distance traveled (in miles) and running time (in seconds) of the device.  These are reset on device inactivation.",
  "Fixed an issue where GPS points would rarely get 'stuck' on stale locations in trips and state feeds.  GPS will now always provide its location, even when the accuracy is low, except in /events.  See known issue below.",
  "Thresholds for Day/Night detection adjusted to allow more intelligent switching between day and night inside camera video modes.",
  "Fixed issue where day/night transitions could happen during intermittant lighting changes.",
  "System time on XT8890 will now respect timezone offsets."
];

export const R2_4_08_22_KnownIssues = [
  "ADAS features will be disabled during live view sessions.",
  "Audio setting takes some time to take effect (< 30 seconds).",
  "Occasionally video on screen has slight lag.",
  "Devices not mounted in a vehicle may trigger aggressive driving events on startup.",
  "Diagnostic Trouble Codes are corrupted if more than 10 are received from the vehicle.",
  "Forward Collision Warning is overly sensitive.  It is recommended it is not enabled at this time.",
  "Occasionally, red 'Recording' dot on camera display will be there indefinitely after an 'OK Presto' clip is made.",
  "After deactivating a camera and then re-activating it, camera will use previous settings until the next reboot.",
  "Occasionally, sliding 'up' on the screen will not turn on the inside camera and the 'outside' camera view will be stretched poorly.  Fix is to swipe down, then up again.",
  "When changing the resolution of the outside camera, the camera needs to restart (either a reboot or going into guard mode/exiting) before the new resolution will take effect.",
  "When the device has no GPS position, it will report the last known accurate location for events until it receives a new location.",
  "Events and Telemetry/State use two different sources for position, leading to inconsistencies in reported location.",
  "When large amounts of clips are generated, media will not be available until all media completes uploading.",
  "If a yellow alert (bump) escalates to a red alert (glassbreak), only the red alert will upload media.",
];
