export const AppConfiguration = {
  "Amplify": {
    Analytics: {
      disabled: true
    },
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // EDKUserPool
      identityPoolId: 'us-west-2:4e7c6b60-7a6d-4aad-aace-1798754c9c00',
  
      // REQUIRED - Amazon Cognito Region
      region: 'us-west-2',
  
      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: 'us-west-2',
  
      // OPTIONAL - Amazon Cognito User Pool ID
      // EDK-Portal
      userPoolId: 'us-west-2_kWESDHG36',
  
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      // EDK-Web-Portal
      userPoolWebClientId: '4pl1iqci79gjq29gt52vueflsm',
  
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true
    },
    Storage: {
      AWSS3: {
        bucket: 'owl-external-partner-edk-portal',
        region: 'us-west-2'
      }
    }
  },
  "DemoPortalUrl": "https://embed.portal.partner.owlcam.com",
  "SwaggerPrefix": "owlcam"
}