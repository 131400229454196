import React from "react";
import TitleBlock from "../../app/components/Titles/TitleBlock";

const authFragment = {
  accessKeyId: "string",
  clientId: "string",
  host: "string",
  protocol: "string",
  region: "string",
  secretKey: "string",
  sessionToken: "string",
  subscriptionTopic: "string",
  publishTopic: "string"
};

const thumbOutsideReq = {
  type: "thumbnail",
  camera: "outside",
  replyTo: "(...subscription topic...)"
};

const thumbInsideReq = {
  type: "thumbnail",
  camera: "inside",
  replyTo: "(...subscription topic...)"
};

const thumbnailResponse = {
  type: "thumbnail",
  camera: "outside",
  result: "success",
  timestamp: 1234567890,
  from: "(...publishing topic...)",
  data: "(...image data...)",
  ranges: []
};

const liveViewReq = {
  type: "stream",
  command: "live",
  camera: "inside",
  replyTo: "(...subscription topic...)"
};

const switchCamLiveViewReq = {
  type: "configure",
  camera: "inside",
  replyTo: "(...subscription topic...)"
};

const endLiveViewReq = {
  type: "bye",
  replyTo: "(...subscription topic...)"
};

const mqttConfigReq = {
  type: "history",
  command: "journal",
  replyTo: "(...subscription topic...)"
};

const journalResp = {
  journal: [
    {
      userId: "..",
      state: "guard | inout | out",
      start: "(...timestampInMilliseconds...)",
      end: "(...timestampInMilliseconds...)"
    }
  ],
  type: "history",
  command: "journal",
  timestamp: "(...requestTimestamp...)",
  result: "success"
};

const thumbFastReq = {
  type: "thumbnail",
  camera: "inside",
  timestamp: "(...requestTimestamp...)",
  replyTo: "(...subscription topic...)"
};

const thumbPreciseReq = {
  type: "thumbnail",
  camera: "inside",
  precision: 120,
  timestamp: "(...requestTimestamp...)",
  replyTo: "(...subscription topic...)"
};

const historyThumbResp = {
  type: "thumbnail",
  camera: "inside",
  timestamp: "(...requestTimestamp...)",
  result: "success",
  data: "(...thumbnailImageData...)",
  ranges: []
};

const historyStartReq = {
  type: "stream",
  command: "history",
  camera: "inside",
  timestamp: "(...requestTimestamp...)",
  replyTo: "(...subscription topic...)"
};

const historyPlayReq = {
  type: "history",
  command: "play",
  timestamp: "(...requestTimestamp...)",
  replyTo: "(...subscription topic...)"
};

const historyPauseReq = {
  type: "history",
  command: "pause",
  replyTo: "(...subscription topic...)"
};

const historyPlayResp = {
  type: "history",
  command: "playing",
  timestamp: "(...requestTimestamp...)"
};

const historyStopReq = {
  type: "bye",
  replyTo: "(...subscription topic...)"
};

export default function CameraAPI() {
  return (
    <div className="container-fluid">
      <div className="flex-xl-nowrap row">
        <div className="col-xl-2 col-md-3 col-12 d-flex flex-column sidenav sidenavitem">
          <a href="#settingUp">Setting Up</a>
          <ul>
            <li>
              <a href="#authenticating">Authenticating</a>
            </li>
            <li>
              <a href="#connecting">Connecting</a>
            </li>
          </ul>
          <a href="#thumbnails">Thumbnails</a>
          <ul>
            <li>
              <a href="#outsideCamera">Outside Camera</a>
            </li>
            <li>
              <a href="#insideCamera">Inside Camera</a>
            </li>
            <li>
              <a href="#response">Response</a>
            </li>
          </ul>
          <a href="#liveview">Live View</a>
          <ul>
            <li>
              <a href="#startLiveView">Starting</a>
            </li>
            <li>
              <a href="#switchingCameras">Switching Cameras</a>
            </li>
            <li>
              <a href="#endLiveView">Ending</a>
            </li>
          </ul>
          <a href="#history">History</a>
          <ul>
            <li>
              <a href="#configHistory">Configuration</a>
            </li>
            <li>
              <a href="#videoContentHistory">Video Content</a>
            </li>
            <li>
              <a href="#startHistory">Starting</a>
            </li>
            <li>
              <a href="#endHistory">Ending</a>
            </li>
            <li>
              <a href="#historyExport">Exporting Clips</a>
            </li>
          </ul>
          <a href="#enumerations">Enumerations</a>
          <ul>
            <li><a href="#type">Type</a></li>
            <li><a href="#command">Command</a></li>
            <li><a href="#camera">Camera</a></li>
            <li><a href="#reason">Reason</a></li></ul>
        </div>
        <main className="col-12 col-md-9 col-xl-10 py-md-3 pl-md-5 bd-content" role="main">
          <TitleBlock
            title={'Camera API'}
            release={'Roadmap'} />

          <p className="bd-lead">Use direct messaging to stream live view and history video content from Xirgocam</p>

          <h2 id="settingUp">Setting Up</h2>
          <p>
            Xirgocam utilizes messaging for direct communication between client
            applications and Xirgocam. All messages are sent and received as json objects.
          </p>

          <p>
            Xirgocam utilizes <a href="https://webrtc.org/">WebRTC</a>.
            Your end-customer-facing application must support a RTC client. Xirgocam messaging will be
            used for IP negotiation before a RTC session is established and for limited control messages once the session is established.
          </p>

          <p>
            Before streaming video from Xirgocam, a secured MQTT connection must first be
            made. After connection, messages are pushed to Xirgocam on the
            publishing topic and messages are received on the subscription
            topic.
          </p>

          <h4 id="authenticating">Authenticating</h4>
          <p>
            Xirgocam MQTT messaging is secured so credentials are required to stream video.
            Everything required to authenticate can be obtained using the Xirgocam API
             <code> /messaging/{"{cameraId}"}/streaming </code>
            which will return:
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(authFragment, null, 2)}
          </pre>

          <h4 id="connecting">Connecting</h4>
          <p>
            Connecting to the Message Service requires a MQTT client. It is
            recommended to use the{" "}
            <a href="https://github.com/aws/aws-iot-device-sdk-js" target="new">
              aws-iot-device-sdk
            </a>
          </p>
          <p>Sample code of connecting using a webclient</p>
          <pre className="alert bg-light code">
            const AWSIoTData = require{"('aws-iot-device-sdk')"};
            <br />
            <br />
            {"//"} Obtained from API call /messaging/token/{"{cameraId}"}
            <br />
            const getTokenResponse = {"{"}
            <br /> accessKeyId: '',
            <br /> clientId: '',
            <br /> host: '',
            <br /> protocol: '',
            <br /> region: '',
            <br /> secretKey: '',
            <br /> sessionToken: '',
            <br /> subscriptionTopic: ''
            <br />
            {"}"}
            <br />
            <br />
            export const mqttClient = AWSIoTData.device{"({"}
            <br /> region: getTokenResponse.region,
            <br /> host: getTokenResponse.host,
            <br /> clientId: getTokenResponse.clientId,
            <br /> protocol: getTokenResponse.protocol,
            <br /> accessKeyId: getTokenResponse.accessKeyId,
            <br /> secretKey: getTokenResponse.secretKey,
            <br /> sessionToken: getTokenResponse.sessionToken,
            <br /> maximumReconnectTimeMs: 5000, // (Optional)
            <br /> debug: true, // (Optional)
            <br />
            {"}"});
            <br />
            <br />
            function mqttClientConnectHandler () {"{"}
            <br /> console.log('mqttClientConnectHandler');
            <br /> mqttClient.subscribe(getTokenResponse.subscriptionTopic);
            <br />
            {"}"};
            <br />
            <br />
            function mqttClientReconnectHandler () {"{"}
            <br /> console.log('mqttClientReconnectHandler');
            <br />
            {"}"};
            <br />
            <br />
            function mqttClientMessageHandler (topic, payload) {"{"}
            <br /> console.log('mqttClientMessageHandler')
            <br /> console.log('message:' + topic + ':' + payload.toString());
            <br />
            {"}"};
            <br />
            <br />
            function mqttClientErrorHandler (error) {"{"}
            <br /> console.log(error);
            <br />
            {"}"};
            <br />
            <br />
            mqttClient.on('connect', mqttClientConnectHandler);
            <br />
            mqttClient.on('reconnect', mqttClientReconnectHandler);
            <br />
            mqttClient.on('message', mqttClientMessageHandler);
            <br />
            mqttClient.on('error', mqttClientErrorHandler);
            <br />
          </pre>

          <h2 id="thumbnails">Thumbnails</h2>
          <p>
            To request a recent thumbnail, first the client must get
            authentication info, connect using that authentication information,
            and subscribe to the subscription topic.
          </p>

          <p>
            Once connected and subscribed on MQTT, the client may send messages
            to Xirgocam to request a recent thumbnail. If Xirgocam is online, a
            response will be sent (usually in well less than 1 second). If
            Xirgocam is offline, no response will be sent. The client will need to
            handle this condition with a timeout with a recommended value of 15
            seconds.
          </p>

          <h4 id="outsideCamera">Outside Camera Request</h4>
          <pre className="alert bg-light code">
            {JSON.stringify(thumbOutsideReq, null, 2)}
          </pre>

          <h4 id="insideCamera">Inside Camera Request</h4>
          <pre className="alert bg-light code">
            {JSON.stringify(thumbInsideReq, null, 2)}
          </pre>

          <h4 id="response">Response</h4>
          <p>
            Upon successful recepit of a request, Xirgocam shall send a response.
            Thumbnail image data shall be in base64-encoded jpg with resolution
            of 640x360
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(thumbnailResponse, null, 2)}
          </pre>

          <h2 id="liveview">Live View</h2>

          <h4 id="startLiveView">Starting</h4>
          <p>To start a live view session, send the start message to Xirgocam</p>
          <pre className="alert bg-light code">
            {JSON.stringify(liveViewReq, null, 2)}
          </pre>

          <h4 id="switchingCameras">Switching Cameras</h4>
          <p>
            Switching cameras while in an active live view session is fully
            supported
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(switchCamLiveViewReq, null, 2)}
          </pre>

          <h4 id="endLiveView">Ending</h4>
          <p>
            To end the live view session, stop the local WebRTC client and send
            the end message to the camera. Make sure that end-customer-facing application lifecycle ensures that end is always called.
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(endLiveViewReq, null, 2)}
          </pre>


          <h2 id="history">History</h2>
          <p>
            Xirgocam supports streaming and exporting clips from the history
            stored on Xirgocam's internal storage.
          </p>

          <h4 id="configHistory">Configuration Request</h4>
          <p>
            History requires both MQTT and WebRTC. MQTT is used for
            configuration, metadata, and sending transport controls. Video will
            be sent over the RTC session. Once a MQTT session has been set up
            and established, the earliest date of video stored on Xirgocam can be
            read using this request:{" "}
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(mqttConfigReq, null, 2)}
          </pre>

          <h4 id="configHistoryResponse">Configuration Response</h4>
          <p>
            A single reply from Xirgocam will consist of a journal, detailing the
            current buffer information of the camera.
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(journalResp, null, 2)}
          </pre>

          <h4 id="videoContentHistory">Video Content Request</h4>
          <p>
            Sometimes, Xirgocam will pause recording to conserve power. Becasuse
            of this, there will be sections where playable content exists and
            other sections in time where there is not video available.
          </p>
          <p>
            Video storage is accessible via 1000s chunks, zero-aligned to
            timestamp (e.g. 1551234000, 1551235000, 1551236000). Each 1000s
            section can be queried to understand the video content found within
            that 1000s duration.
          </p>
          <p>
            There are two types of calls: "fast" request and "precise" request.
            Generally, the fast request will repond much faster, but will
            provide the same thumbnail for the entire 1000s period. The precise
            request will take longer to generate and send back to the client,
            but will be accurate to the nearest 4 second interval.
          </p>

          <b>Fast request</b>
          <p>
            To query a specific 1000s section between the current time and the
            earliest reported history date in the journal:
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(thumbFastReq, null, 2)}
          </pre>

          <b>Precise request</b>
          <pre className="alert bg-light code">
            {JSON.stringify(thumbPreciseReq, null, 2)}
          </pre>

          <h4 id="videoContentHistoryResponse">Video Content Response</h4>
          <p>
            Xirgocam will respond with a thumbnail (if video is present in the
            1000 sec section) that represents the video during the 1000s range
            as well as an array of timestamps under the key of{" "}
            <code>ranges</code>:
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(historyThumbResp, null, 2)}
          </pre>

          <p>
            A response with <code>"failed"</code> for <code>result</code>{" "}
            indicates no video present for the entire 1000s duration.
          </p>

          <p>
            The value for the <code>ranges</code> key is an array of timestamps
            that represent the start time and end time of video data. For
            example: <code>[1552345100, 1552345300]</code>
            means that there is video between the the timestamps of 1552345100
            and 1552345300. Note: there may be many segments. This array shall
            have an even number of items as the returned elements are always
            inclusive of start time + stop time pairs.
          </p>
          <h4 id="startHistory">Starting History</h4>
          <p>
            To start history playback, send the following request (once per
            history session). Note: this call will not start playback. A
            separate call to play must occur after sending this message.
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(historyStartReq, null, 2)}
          </pre>

          <h4 id="startPlaybackHistory">Playback Request</h4>
          <p>
            Send a play request for any timestamp. Sending multiple play
            requests is OK and expected when seeking around:
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(historyPlayReq, null, 2)}
          </pre>

          <h4 id="startHistory">Pause Request</h4>
          <p>Pausing the playback is supported:</p>

          <pre className="alert bg-light code">
            {JSON.stringify(historyPauseReq, null, 2)}
          </pre>

          <h4>Playback Responses</h4>
          <p>
            During playback, Xirgocam will periodically and regularly report the
            currently playing timestamp. This message stream shall exist during
            playback. When paused, these messages shall not be sent by Xirgocam.
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(historyPlayResp, null, 2)}
          </pre>

          <h4 id="endHistory">Ending Request</h4>
          <p>
            To end the history session, stop the local WebRTC client and send
            the end message to Xirgocam.
          </p>

          <pre className="alert bg-light code">
            {JSON.stringify(historyStopReq, null, 2)}
          </pre>

          <h4 id="historyExport">Exporting Clips</h4>
          <p>
            Functionality for exporting clips history clips will be exposed via
            API. This API will allow for adding a title to the exported clip.
            Once the clip is uploaded and made available, an event will be sent
            over typical notification channels. See the{" "}
            <a href={"/xirgocam-api"}>Xirgocam API</a> and the{" "}
            <a href={"/events-api"}>Events API</a> for more information.
          </p>
          <h2 id="enumerations">Enumerations</h2>
          <h4 id="type">type</h4>
          <ul>
            <li><code>history</code></li>
            <li><code>stream</code></li>
            <li><code>configure</code></li>
            <li><code>bye</code></li>
          </ul>

          <h4 id="command">command</h4>
          <ul>
            <li><code>live</code></li>
            <li><code>play</code></li>
            <li><code>pause</code></li>
          </ul>

          <h4 id="camera">camera</h4>
          <ul>
            <li><code>inside</code></li>
            <li><code>outside</code></li>
          </ul>

          <h4 id="reason">reason</h4>
          <ul>
            <li><code>busy</code></li>
            <li><code>insufficientCredits</code></li>
            <li><code>cameraTooHot</code></li>
          </ul>
        </main>
      </div>
    </div>
  );
}
