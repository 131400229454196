export function scrollToElementById (itemId) {
  console.log('scrollToElementById', itemId);
  const element = document.getElementById(itemId.slice(1));
  scrollToElement(element);
}

export function scrollToElement (element) {
  if (!element || typeof element.scrollIntoView !== 'function') {
    return;
  }

  element.scrollIntoView();
  window.scroll(0, window.scrollY - 62);
}

export function sleepPromise (delay) {
  console.log(Date.now());
  return new Promise((resolve)  => {
    setTimeout(() => {
      console.log(Date.now());
      resolve();
    }, delay);
  })
}