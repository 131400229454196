export const R2_03_11_24_ReleaseSummary = [
  "Release Version: 33.1 PRODUCTION 1705563x33",
  "Summary: This is a major feature release that enables Proprietary Vehicle Data support from the OBD-PRO cable.",
  "No firmware versions are deprecated with this release."
];

export const R2_03_11_24_Features = [
  "OBD-PRO Proprietary vehicle data support [XIR2-81][XIR2-966][XIR2-967][XIR2-1032][XIR2-1436][XIR2-1457][XIR2-1461][XIR2-1501][XIR2-1536][XIR2-1539][XIR2-1553][XIR2-1554][XIR2-1555][XIR2-1556][XIR2-1558][XIR2-1594][XIR2-1597][XIR2-1602][XIR2-1607][XIR2-1614][XIR2-1635][XIR2-1640].",
  "Please contact your FAE with serial numbers to ensure they are enabled on the PPID server for any existing cameras. New production cameras will automatically be enabled.",
  "New setting: \"vbus.ppids.isEnabled\" must be true.",
  "Please see Xirgocam Enterprise Development Kit Portal for more information on API <a href=\"/definitions#vbus\">definitions</a>.",
  "Added OBD-PRO data to debug menu [XIR2-1545]."
];

export const R2_03_11_24_CompatibilityIssues = [];

export const R2_03_11_24_Improvements = [
  "Updated DMS to latest version [XIR2-1018]."
];

export const R2_03_11_24_IssuesAddressed = [
  "Ignition Threshold and Deadband used by OBD-PRO cable now respect cloud settings [XIR2-1496].",
  "System will no longer restart occasionally at vehicle ignition on [XIR2-1573].",
];

export const R2_03_11_24_Errata = [
  "A hard limit of 29 \"nuggets\" may be configured on OBD-PRO.",
  "Devices not mounted in a vehicle may trigger aggressive driving events on startup.",
  "A small subset of devices cannot update firmware on serial communication chip; they will need to be replaced to use OBD-PRO Cable upon its delivery and will have corrupted DTCs if > 10 DTCs are present. (No particular firmware).",
  "When Hyperlapse is enabled, /trips/camera API will report hyperlapseVideo: false until a hyperlapse video is uploaded, then switch to hyperlapseVideo: true."
];

export const R2_03_11_24_KnownIssues = [
  "ADAS/DMS",
  [
    "ADAS features will be disabled during live view sessions. (R32.3) [XIR2-236]",
    "Forward Collision Warning is overly sensitive even on Low settings. (R32.6) [XIR2-857]",
    "DMS reports many false positives on Cellphone Usage, especially when faces are shadowed (R33.1) [XIR2-1696]",
    "DMS may take longer than usual to calibrate, or not calibrate at all, in certain situations (R33.1) [XIR2-1696]."
  ],
  "Camera Recording",
  [
    "If devices are without network for several months, they may fill up their storage with media clips and be unable to record new video until all media is uploaded. (All version) [XIR2-1110].",
    "Occasionally, OK Presto voice recognition is disabled when device has recently regained network connection (R32.5) [XIR2-761].",
    "Occasionally, if the camera is live viewed while it is in guard mode, audio will cut out after approximately 20 seconds.  This affects Live View only and does not affect history/event audio. (R32.9) [XIR2-1358].",
    "Occasionally, history audio will become out of sync with the audio setting, causing history recordings to have audio when the setting is disabled and vice-versa. (R32.10.1) [XIR2-1644]",
  ],
  "Guard Mode",
  [
    "Extremely rarely, the device will not properly enter guard mode at the end of the timer. Bumps, trips, or other events will reset this and normal function will resume. (All versions) [XIR-700]",
    "Extremely rarely, the device will not enter guard mode at the end of the timer if “guard mode ignitionOnDisable” is enabled. (R32.9) [XIR2-1360]",
  ],
  "LCD Display",
  [
    "Occasionally video on display has slight lag. This does not affect recorded video. (All versions) [XIR2-229] [XIR2-944].",
    "Occasionally, sliding 'up' on the display will not turn on the inside camera and the 'outside' camera view will be stretched poorly.  Fix is to swipe down, then up again. (All versions) [XIR2-219].",
    "Occasionally, red 'Recording' dot on LCD display will be there indefinitely after an 'OK Presto' clip is made. (All versions) [XIR2-246].",
    "A slight lag is noticed in switching from Night to Day mode on the LCD display (R32.5) [XIR-578].",
    "When displayRequiresIgnition is enabled, it is possible display will not come on during an Ignition On event (R32.7) [XIR2-884].",
    "Swiping up and down on the display in rapid succession will cause the “Inside Camera” setting to become out of sync with what is reflected in the cloud (All versions) [XIR2-1108].",
    "Occasionally, changing orientation from the LCD Settings menu during a trip will cause the LCD screen to not turn on.  Resolved by rebooting device (hold down button on side for 5 seconds) (R32.9) [XIR2-1322].",
  ],
  "Telemetry",
  [
    "Occasionally, event files will not be uploaded until the device’s next scheduled reboot (Fleet only) (R32.5) [XIR2-924].",
    "History clips will include the current telemetry information instead of the telemetry of the requested timestamp (R32.5) [XIR2-252].",
  ],
  "Watermarking",
  [
    "When changing watermarking settings, the size of the watermark will be very large during any video clip that includes the exact time the setting was changed. (R32.9) [XIR2-1106].",
    "Timestamps on watermarked videos will sometimes jump by 2 seconds at a time. (R32.9) [XIR2-1375].",
    "Watermarks are not available on Hyperlapse videos (R32.10) [XIR2-1540].",
    "Watermarks may occasionally be in the center of the video, rather than the side. (R33.1) [XIRCAM2-1450].",
  ],
  "Other",
  [
    "Audio setting takes some time to take effect (< 30 seconds). (All versions) [XIR2-224].",
  ],
];
