export const R2_9_16_22_ReleaseSummary = [
  "Release Version: 32.8  Quality and stability focus",
];

export const R2_9_16_22_Features = [
  "New cloud setting to force CAN bus discovery using response from service 1 pid 1. May resolve issues retrieving values from the vehicle bus. USE AT YOUR OWN RISK! [XIR2-244]",
  "GPS pinning can be enabled with a new cloud setting. This reduces superfluous changing GPS points while stationary [XIR2-29]",
  "New setting to disable guard mode when ignition on is detected [XIR2-178]",
];

export const R2_9_16_22_CompatibilityIssues = [];

export const R2_9_16_22_Improvements = [
  "Timestamps are now centralized and use a variety of prioritized sources for determining the current time. In order: NTP, GPS, NITZ, system clock. [XIR2-181]",
  "GPS telemetry retrieval is centralized and the same location retrieval is used for all webhooks and events [XIR2-212]",
  "Devices with IR lights should now always stay in IR mode between sunset and sunrise, regardless of environmental brightness [XIR2-177]",
  "The OTA process was made more robust and will be able to recover the device from more scenarios [XIR2-29]",
  "Power consumption during guard mode was improved [XIR2-947]",
];

export const R2_9_16_22_Bugfixes = [
  "Fixed issue where the system timestamp was able to be set to extremely future years (2080) on boot [XIR2-907]",
  "The cell-phone.json file for cell phone events is now properly parsed JSON instead of a single string [XIR2-791]",
  "DMS calibration now automatically determines the width and height of the face region of interest [XIR2-325]",
  "Fixed timing issue causing the device to occasionally enter guard mode while mid-trip [XIR2-259]",
  "Fixed a race condition with backend authentication that could rarely cause a device to boot loop [XIR2-269]",
];

export const R2_9_16_22_KnownIssues = [
  "ADAS features will be disabled during live view sessions. [XIR-810]",
  "Audio setting takes some time to take effect (< 30 seconds). [XIR-811]",
  "Occasionally video on screen has slight lag. [XIR-541]",
  "Devices not mounted in a vehicle may trigger aggressive driving events on startup. [XIR-589]",
  "Occasionally, red 'Recording' dot on camera display will be there indefinitely after an 'OK Presto' clip is made. [XIR-582]",
  "Occasionally, sliding 'up' on the screen will not turn on the inside camera and the 'outside' camera view will be stretched poorly.  Fix is to swipe down, then up again. [XIR-530]",
  "When the device has no GPS position, it will report the last known accurate location for events until it receives a new location. [XIR-690]",
  "In certain cases, display will not turn back on when voltage goes over 13V when displayRequiresIgnition is enabled. [XIR2-884]",
  "A slight lag is noticed in switching from Night to Day mode on the LCD screen",
  "In the case of improper factory configured camera, during boot immediately after updating to 32.7 firmware, users may experience inaccurate journal and/or failed History requests for the first hour after updating firmware.",
  "During certain background task operations, guard mode current could temporarily exceed nominal values.",
];
