export const R2_1_Summary = 'This is a partial release of the R2 API collection that OWL previously communicated as a singular release. We have broken this collection of APIs down into 3 separate releases to ensure features are published in priority order as early as possible.';

export const R2_1_Features = [
    'Clips API: Clip media requests now return accelerometer, GPS, and inside and outside images (if available)',
    'Camera Settings API: EnableCAN (true / false): For drivers with the OBD-VIN power cable, CAN (Car Area Network) data may be available, depending on the vehicle. The setting is an override to enable or disable the CAN bus data from being read. NOTE: CAN data will not be available until the end of Q1',
    'Camera Settings API: insideCameraMode (“driverManaged” / “off”): Defines how the driver can control the inside camera of the Xirgocam.',
    'Camera Settings API: outsideRes (1440p / 1080p / 720p): Defines the recording resolution of the outside camera of the Xirgocam. Inside camera resolution is fixed at 720p (1280x720).',
    'Camera Vehicle API: Fleet Managers can set the VIN',
    'Camera Activation API v1: Fleet Managers can now queue activation for offline cameras'
]
export const R2_1_CompatibilityIssues = [
    'Camera Settings Model: beeps (“on” / ”off”) → enableBeeps (true / false)',
    'Camera Settings Model: smallAlertEnable (0 / 1) → enableMotionClips (true / false)',
    'Camera Settings Model: videoBufferSec (0 / 86400 / 30) → videoBufferMode (“max” / “day” / “min”)',
    'Camera Settings Model: audioSetting (“cameraControlled” / “off”) → audioMode (“driverManaged” / “off”)'
]
export const R2_1_Bugfixes = [
    'Clips API: /clip/{clipId} now properly handles the Content-Disposition header values of “inline” or “attachment”',
    'Clips API: "dateFrom" was not respected when calling /clip/camera/{cameraId}'
]
