export const OS_1_Summary = 'This is the initial release of the OS API collection.';

export const OS_1_Features = [
  'New API (SettingsAPI under Settings Release 1) for managing camera settings has been added',
  'Settings for managing audio and insideCamera using the XirgocamAPI have been deprecated. Please use the audio and insideCamera settings in the SettingsAPI.',
  'Settings for managing display, tapToCreate and orientation have been added',
  'Settings for managing ADAS events have been added. Events include: tailgating, frontalCollisionWarning, stopAndGo, and laneDeparture. NOTE: The camera is currently not generating ADAS clips. This will be updated in a future firmware release',
  'Settings for managing aggressive driving events have been added. Events include: harshAcceleration, harshBraking, harshCorneringLeft, harshCorneringRight',
  'Settings for managing camera installation parameters have been added. It is recommended to configure these settings for best detection results. Fields include: mountHeight'
]
export const OS_1_CompatibilityIssues = [
  'enableBeeps (true / false) -> camera.beeps.value (true / false)',
  'enableCan (true / false) -> camera.can.value (true / false)',
  'enableMotionClips (true / false) -> camera.motionAlerts.value  (true / false)',
  'audioMode (driverManaged / off) -> camera.audio.value (x / x). This setting will not be transferred. Please use the SettingsAPI to configure this feature.',
  'insideCameraMode (driverManaged / off) -> camera.insideCamera.value (x / x) This setting will not be transferred. Please use the SettingsAPI to configure this feature.',
  'standbyTime (72, 48, 24) -> camera.standbyTime.value (72, 48, 24)',
  'videoBufferMode (day, max, min) -> camera.videoBufferSec.value (86400, 30, 0)',
  'outsideRes (1440, 1080, 720) -> camera.outsideRes.value (1440, 1080, 720)',
  'vehicleColor (red, white, blue, yellow, green, lightGray, darkGray) -> camera.vehicle.color (red, white, blue, yellow, green, lightGray, darkGray)',
  'vehicleName -> camera.vehicle.name',
  'vehicleType (sedan, coupe, SUV, jeep, van, truck, RV, semi) -> camera.vehicle.type (sedan, coupe, SUV, jeep, van, truck, RV, semi)',
  'okPrestoMode (clip / marker) -> camera.okPresto.value (clip / marker)'
]
export const OS_1_Bugfixes = []
