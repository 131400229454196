export const XirgocamTableContentListRoadmap = [
  {
    id: "#operations-tag-camera",
    name: "Camera",
  },
  {
    id: "#operations-tag-camera_activation",
    name: "Camera Activation",
  },
  {
    id: "#operations-tag-camera_connection",
    name: "Camera Connection",
  },
  {
    id: "#operations-tag-camera_sim",
    name: "Camera Sim",
  },
  {
    id: "#operations-tag-camera_telemetry",
    name: "Camera Telemetry",
  },
  {
    id: "#operations-tag-camera_vehicle",
    name: "Camera Vehicle",
  },
  {
    id: "#operations-tag-clips",
    name: "Clips",
  },
  {
    id: "#operations-tag-diagnostics",
    name: "Diagnostics",
  },
  {
    id: "#operations-tag-events",
    name: "Events",
  },
  {
    id: "#operations-tag-messaging",
    name: "Messaging",
  },
  {
    id: "#operations-tag-trips",
    name: "Trips",
  },
  {
    id: "#operations-tag-branding",
    name: "Branding",
  },
  {
    id: "#operations-tag-firmware_management",
    name: "Firmware Management",
  },
  {
    id: "#model-Camera",
    name: "Schemas",
  },
];
