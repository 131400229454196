import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

//COMPONENT
import TableContentList from "../app/components/TableContent/TableContentList";
import TitleBlock from "../app/components/Titles/TitleBlock";

// STYLES 
import "../app/styles/pages/Events.styles.css";

//SWAGGER 
import R1SwaggerSpec from "../versionContent/r1/events.swagger.json";
import R2SwaggerSpec from "../versionContent/r2/events.swagger.json";
import RoadmapSwaggerSpec from "../versionContent/roadmap/events.swagger.json";

class EventsAPI extends React.Component {
  state = { url: null, version: this.props.version };

  async updateComponent(version) {
    const spec = getSwaggerForVersion(version);
    this.setState({ spec, version })
  }

  async componentDidMount() {
    this.updateComponent(this.props.version);
  }

  async componentDidUpdate() {
    const { version } = this.props
    if (this.state.version !== version) {
      this.updateComponent(version);
    }
  }

  render() {
    return (
      <div className="flex-xl-nowrap row">
        <TableContentList version={this.props.version} name={"events"} />
        <main className="col-12 col-md-9 col-xl-10 py-md-3 pl-md-5 bd-content clearcolfmt" role="main">
          <TitleBlock
            title="Event Webhooks"
            release={this.state.version}
          />
          <div >
            <p>A webhook in web development is a method of augmenting or altering the behavior of a web page, or web application, with custom callbacks</p>
            <p>When that event occurs, the Xirgocam cloud service makes a HTTPS request to the URL configured for the webhook</p>
            <p>Using the server-to-server notification service is optional but highly recommended. After your webhook server is set up and you contact your technical contact at Xirgocam to share the endpoints, you can start receiving notifications</p>
            <p>Xirgocam webhooks support authentication schemes</p>
            <ul>
              <li><strong>API key</strong> where you specify the header and key value</li>
              <li><strong>Basic</strong> using base64 encoded username/password login</li>
              <li><strong>Bearer</strong> using authorization token</li>
            </ul>
            <p>Xirgocam supports two types of webhook servers, but Xirgocam recommends using "path-based" configuration</p>
            <ul>
              <li><strong>Path-based</strong> where every webhook will have a unique URL resource</li>
              <li><strong>Host-based</strong> where all webhooks will be delivered to the same URL</li>
            </ul>
          </div>
          <SwaggerUI
            id="swagger-events-api"
            spec={this.state.spec}
            docExpansion="list"
            className="padding-16"
          />
        </main>
      </div>
    ); 
  }
}

function getSwaggerForVersion(version){
  switch(version){
    case "R1":
      return R1SwaggerSpec;
    case "R2":
      return R2SwaggerSpec;
    case "Roadmap":
      return RoadmapSwaggerSpec;
    default:
      return R1SwaggerSpec;
  }
}

export default EventsAPI;
