import React from 'react'

// APP COMPONENT
import TableContentSection from './TableContentSection'

const TableContentListWithSections = ({ contentSections }) => {
  return (
    <div className="col-xl-2 col-md-3 col-12 d-flex flex-column sidenav sidenavitem">
      {contentSections.map((section) => {
        return (
          <TableContentSection
            headerId={section.id}
            headerName={section.name} 
            contentList={section.list}
          />
        )
      })}
    </div>
  )
}

export default TableContentListWithSections;