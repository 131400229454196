export const R2_3_04_22_Summary =
  "The XT8890 Camera is supported in this release. The XT8890 includes an update to the cellular modem in the camera, moving from the Sierra Wireless HL7548 to the Sierra Wireless RC7611. This change is due to supply chain challenges, there is no difference in Form, Fit, or Function.";

export const R2_3_04_22_Features = [
  'Tailgating sensitivity models have been improved. See <a href="/definitions#tailgating">Definitions</a>',
  'Night sensitivity settings have been added for ADAS tailgating. See <a href="/definitions#tailgating">Definitions</a>',
  "Lane departure detection is released for BETA testing to allow for partner feedback and fine tuning.",
  "The library for ADAS functionality is updated to the latest version.",
  "In-Cab Alerts (Driver Alerts) are now queued sequentially if multiple events happen at the same time.  Any events older than 30 seconds will be removed from the queue.",
  "The enableCAN settings default value is set to false for consumer cameras only.",
  "The eventQueueSize default has been increased from 1 to 100. This ensures more consistent and robust message sending from the device.",
  "Settings for insideCamera and audio when leaving Guard Mode have been introduced.  Default is true for both, enabling audio and inside camera media for movement and glassBreak events.  The other “global” settings will be respected when the camera is NOT in guard mode.",
];

export const R2_3_04_22_CompatibilityIssues = [];

export const R2_3_04_22_Bugfixes = [
  "Power savings: Several major changes to power draw in Guard Mode including a reduction of an average of 40% of current consumption during Guard Mode.",
  "Fixed issue where no ADAS events could trigger for 21 seconds after an event of the same type.",
  "Red Alert (Damage) events no longer temporarily freeze display and media during clip.",
  "Fixed inconsistent GPS file uploads causing some events to not have GPS data.",
  "Cameras with IR LEDs now turn them on when waking from guard mode regardless of previous state. After 2 seconds of calibration the lights will turn off if the ambient light is bright enough.",
  "Event file uploads reliability has been improved.",
  "Fixed freeze on initial camera boot-up.",
];

export const R2_3_04_22_KnownIssues = [
  "ADAS features will be disabled during live view sessions.",
  "Audio setting takes some time to take effect (< 30 seconds).",
  "Occasionally video on screen has slight lag.",
  "Devices not mounted in a vehicle may trigger aggressive driving events on startup.",
  "Diagnostic Trouble Codes are corrupted if more than 10 are received from the vehicle.",
  "Forward Collision Warning is overly sensitive.  It is recommended it is not enabled at this time.",
];
