export const DefinitionTableContentSettingsRelease1 = [
  {
    id: '#settings',
    name: 'Camera Settings',
    list: [
      {
        id: '#audio',
        name: 'Audio',
      },
      {
        id: '#insideCamera',
        name: 'Inside Camera',
      },
      {
        id: '#display',
        name: 'Display',
      },
      {
        id: '#displayRequiresIgnition',
        name: 'Display Requires Ignition',
      },
      {
        id: '#okPresto',
        name: 'Ok Presto',
      },
      {
        id: '#videoBufferSec',
        name: 'Video Buffer Seconds',
      },
      {
        id: '#outsideRes',
        name: 'Outside Camera Resolution',
      },
      {
        id: '#standbyTime',
        name: 'Standby Time',
      },
      {
        id: '#beeps',
        name: 'Beeps',
      },
      {
        id: '#CAN',
        name: 'CAN',
      },
      {
        id: '#motionAlerts',
        name: 'Motion Alerts',
      },
      {
        id: '#vehicle',
        name: 'Vehicle',
      },
      {
        id: '#tapToCreateClip',
        name: 'Tap to Create Clip',
      },
      {
        id: '#orientation',
        name: 'Orientation',
      },
      {
        id: '#bumpSensitivity',
        name: 'Bump Sensitivity',
      },
      {
        id: '#inCabAlerts',
        name: 'In Cab Alerts',
      },
      {
        id: '#eventQueueSize',
        name: 'Event Queue Size',
      },
      {
        id: '#guardMode',
        name: 'Guard Mode',
      },
      {
        id: '#ignition',
        name: 'Ignition',
      },
      {
        id: '#startupScreen',
        name: 'Startup Screen',
      },
    ],
  },
  {
    id: '#sceneSettings',
    name: 'Scene Settings',
    list: [
      {
        id: '#mountHeight',
        name: 'Mount Height',
      },
    ],
  },
  {
    id: '#adasSettings',
    name: 'ADAS Settings',
    list: [
      {
        id: '#tailgating',
        name: 'Tailgating',
      },
      {
        id: '#frontalCollisionWarning',
        name: 'Frontal Collision Warning',
      },
      {
        id: '#stopAndGo',
        name: 'Stop and Go',
      },
      {
        id: '#laneDeparture',
        name: 'Lane Departure',
      },
    ],
  },
  {
    id: '#dmsSettings',
    name: 'DMS Settings',
    list: [
      {
        id: '#distracted',
        name: 'Distracted',
      },
      {
        id: '#cellPhone',
        name: 'Cell Phone',
      },
      {
        id: '#fatigue',
        name: 'Fatigue',
      },
    ],
  },
  {
    id: '#aggressiveDrivingSettings',
    name: 'Aggressive Driving Settings',
    list: [
      {
        id: '#harshAcceleration',
        name: 'Harsh Acceleration',
      },
      {
        id: '#harshBraking',
        name: 'Harsh Braking',
      },
      {
        id: '#harshCorneringRight',
        name: 'Harsh Cornering Right',
      },
      {
        id: '#harshCorneringLeft',
        name: 'Harsh Cornering Left',
      },
    ],
  },
  {
    id: '#eventSettings',
    name: 'Event Settings',
    list: [
      {
        id: '#telemetryEvent',
        name: 'Telemetry',
      },
      {
        id: '#tripsEvent',
        name: 'Trips',
      },
      {
        id: '#stateEvent',
        name: 'State',
      },
      {
        id: '#modeEvent',
        name: 'Mode',
      },
      {
        id: '#diagnosticsEvent',
        name: 'Diagnostics',
      },
      {
        id: '#eventsEvent',
        name: 'Events',
      },
      {
        id: '#systemEvent',
        name: 'System',
      },
    ],
  },
  {
    id: '#gnssSettings',
    name: 'GNSS Settings',
    list: [
      {
        id: '#pinning',
        name: 'Pinning',
      },
    ],
  },
  {
    id: '#overrideSettings',
    name: 'Override Settings',
    list: [
      {
        id: '#forceCan',
        name: 'Force CAN',
      },
    ],
  },
  {
    id: '#hyperlapseSettings',
    name: 'Hyperlapse Settings',
    list: [
      {
        id: '#insideCameraHyperlapse',
        name: 'Inside Camera',
      },
      {
        id: '#outsideCameraHyperlapse',
        name: 'Outside Camera',
      },
    ],
  },
  {
    id: '#watermarkSettings',
    name: 'Watermark Settings',
    list: [
      {
        id: '#isEnabledWatermark',
        name: 'Is Enabled',
      },
      {
        id: '#options',
        name: 'Options',
      },
    ],
  },
  {
    id: "#vbusSettings",
    name: "VBUS Settings",
    list: [
      {
        id: '#ppids.isEnabled',
        name: 'PPIDs Enabled',
      },
      {
        id: '#nuggets',
        name: 'PIDs/Nuggets',
      },
    ],
  },
];
