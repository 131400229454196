import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// AMPLIFY
import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator, SignIn, ConfirmSignIn, RequireNewPassword, ForgotPassword, Loading, VerifyContact, ConfirmSignUp, TOTPSetup } from 'aws-amplify-react';

// APP PAGES
import Home from './pages/Home';
import CameraAPIServerLoad from './pages/CameraAPIServerLoad';
import EventsAPI from './pages/EventsAPI';
import XirgocamAPI from './pages/XirgocamAPI';
import SettingsAPI from './pages/SettingsAPI';
import Definitions from './pages/Definitions';
import ReleaseNotes from './pages/ReleaseNotes';
import AdminAPI from './pages/AdminAPI';

// APP COMPONENTS
// import MainNavbar from './components/mainNavBar/MainNavbar';
import Navbar from './app/components/Navbar/Navbar';

// STYLES
import './App.css';

// OTHER
import { scrollToElement, sleepPromise } from './utils/ScrollUtility';
import { AppConfiguration } from './configuration/App.configuration';

// UTILS
import { isDemoOrDevelopmentEnvironment } from './utils/environmentControl';

Amplify.configure(AppConfiguration.Amplify);

if (isDemoOrDevelopmentEnvironment()) {
  console.log(window.location.origin, ' - process.env -> ', process.env);
}

class App extends React.Component {
  state = {
    pageLoadTargetId: undefined,
    partnerPoolId: undefined,
    version: 'R2',
  };

  componentDidMount = async () => {
    // total hack
    document.getElementById('loginBanner').hidden = true;
    document.getElementById('loginLowerBanner').hidden = true;
    let currentUserInfo = await Auth.currentUserInfo();
    if (!currentUserInfo || !currentUserInfo.attributes) {
      return;
    }
    if (currentUserInfo.attributes['custom:partnerPoolId']) {
      const partnerPoolId = currentUserInfo.attributes['custom:partnerPoolId'];
      this.setState({ partnerPoolId });
    }
    if (currentUserInfo.attributes.email) {
      this.setState({ userEmail: currentUserInfo.attributes.email });
    }

    const href = window.location.href;
    console.log('href', href);
    const id = href.split('#')[1];
    console.log('id', id);
    if (id) {
      this.scrollToPageLoadTargetId(id);
    }
  };

  scrollToPageLoadTargetId = async pageLoadTargetId => {
    console.log('scrollToPageLoadTargetId');
    let element = document.getElementById(pageLoadTargetId);
    console.log('element', typeof element);
    console.log('element', element);
    if (!element) {
      await sleepPromise(1000);
      element = document.getElementById(pageLoadTargetId);
      console.log('element', typeof element);
      console.log('element', element);
      if (!element) {
        return;
      }
    }

    scrollToElement(element);
  };

  handleSelectVersion = e => {
    const version = e;
    this.setState({ version });
  };

  handleSignOut = () => {
    Auth.signOut();
    // total hack
    document.getElementById('loginBanner').hidden = false;
    document.getElementById('loginLowerBanner').hidden = false;
  };

  render() {
    const { userEmail, partnerPoolId, version } = this.state;
    return (
      <BrowserRouter>
        {/* <MainNavbar auth={Auth} partnerPoolId={partnerPoolId} version={version} handleSelectVersion={this.handleSelectVersion}/> */}
        <div className='container-flex' style={{ padding: 0 }}>
          <Navbar auth={Auth} version={version} userEmail={userEmail} partnerPoolId={partnerPoolId} handleSignOut={this.handleSignOut} handleSelectVersion={this.handleSelectVersion} />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/xirgocam-api' render={props => <XirgocamAPI {...props} version={version} />} />
            <Route path='/events-api' render={props => <EventsAPI {...props} version={version} />} />
            <Route path='/camera-api' render={props => <CameraAPIServerLoad {...props} version={version} />} />
            <Route path='/settings-api' render={props => <SettingsAPI {...props} version={version} />} />
            <Route path='/admin-api' render={props => <AdminAPI {...props} version={version} />} />
            <Route path='/definitions' render={props => <Definitions {...props} version={version} />} />
            <Route path='/release-notes' component={ReleaseNotes} />
            <Route component={Home} />
          </Switch>
        </div>
        {/* <Footer /> */}
      </BrowserRouter>
    );
  }
}

export default withAuthenticator(App, {
  authenticatorComponents: [<SignIn />, <ConfirmSignIn />, <RequireNewPassword />, <ConfirmSignUp />, <VerifyContact />, <ForgotPassword />, <TOTPSetup />, <Loading />],
});
