export const OS_1_1_Summary = 'This is a minor release of the OS API collection that introduces expanded options for allowing driver access to settings, new settings for managing guard mode sensitivity and in cab alerts, and minor bug fixes.';

export const OS_1_1_Features = [
  'Changes made to settings locally on the Xirgocam will now be saved to the cloud.',
  'Settings for managing guard mode sensitivity (bumpSensitivity) have been added to the SettingsAPI.',
  'Settings for managing driver access to camera settings have been added for the following settings: audio, insideCamera, display, orientation, and tapToCreateClip.',
  'Settings for managing in cab alerts. Defines if the driver will experience a beep, a fullscreen text notification, and a voice reading for aggressive driving and ADAS events.'
];

export const OS_1_1_CompatibilityIssues = [];

export const OS_1_1_Bugfixes = [
  'POST /settings accepts the model returned from GET /settings/{cameraId}, enabling the read-modify-write pattern.'
]