import React from 'react';
import SwaggerUI from "swagger-ui-react";

import TableContentList from "../app/components/TableContent/TableContentList";
import TitleBlock from "../app/components/Titles/TitleBlock";

import R2SwaggerSpec from "../versionContent/roadmap/settings.swagger.json";
import SettingsRelease1SwaggerSpec from "../versionContent/settings-release1/settings.swagger.json";

class SettingsAPI extends React.Component {
  state = {url: null, version: this.props.version};

  async updateComponent(version) {
    const spec = getSwaggerForVersion(version);
    console.log(spec);
    this.setState({ spec, version })
  }

  async componentDidMount() {
    this.updateComponent(this.props.version);
  }

  async componentDidUpdate() {
    const { version } = this.props
    if (this.state.version !== version) {
      this.updateComponent(version);
    }
  }

  render() {
    return (
      <div className="row">
        <TableContentList version={this.props.version} name={"settings"} />
        <main className="col-12 col-md-9 col-xl-10 py-md-3 pl-md-5 bd-content clearcolfmt" role="main">
          <TitleBlock
            title="Settings API"
            release={this.state.version}
          />
          <SwaggerUI
            spec={this.state.spec}
            docExpansion="list"
            className="padding-16"
          />
        </main>
      </div>
    )
  };

}

function getSwaggerForVersion(version) {
  switch(version) {
    case 'Settings Release 1': 
      return SettingsRelease1SwaggerSpec;
    case 'Roadmap': 
      return R2SwaggerSpec;
    default: 
      return null;
  }
}

export default SettingsAPI;