// REACT + ROUTER
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// BOOTSTRAP COMPONENTS
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
/*import NavDropdown from "react-bootstrap/NavDropdown";*/

// APP COMPONENTS
import NavbarLinks from './NavbarLinks';
import NavbarVersionControl from './NavbarVersionControl';

// ASSETS
import XirgocamLogo from '../Logos/XirgocamLogo';

function NavbarComponent(props) {
  return (
    <Navbar className='topnav-bg' bg='dark' variant='dark' sticky='top' expand='md'>
      <Navbar.Brand>
        <Link to='/'>{<XirgocamLogo height={30} fill='#FFFFFF' />}</Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='mr-auto green' activeKey={'/definitions'}>
          <NavbarVersionControl version={props.version} handleSelectVersion={props.handleSelectVersion} userEmail={props.userEmail} currentPath={props.location.pathname} />
          <NavbarLinks currentPath={props.location.pathname} version={props.version} />
          {/* <NavDropdown title="Samples" id="basic-nav-dropdown">
            <NavDropdown.Item href="">--Coming Soon--</NavDropdown.Item>
          </NavDropdown> */}
        </Nav>
        <Nav pullright='true'>
          <Nav.Link className='disabled'>{props.userEmail}</Nav.Link>
          <Button size='sm' onClick={props.handleSignOut} className='navbar-button'>
            Sign out
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default withRouter(props => <NavbarComponent {...props} />);
