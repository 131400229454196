export const R2_1_11_22_Summary = 'This release introduces the new Event feature as well as expanded DTC functionality. The Event feature replaces the existing Clips functionality.';

export const R2_1_11_22_Features = [
    'Camera Events Webhook: "r2" version added. Fleet managers can now receive updates for detected device events. See <a href="http://portal.partner.owlcam.com/events-api#operations-tag-event">Webhooks</a> and <a href="http://portal.partner.owlcam.com/definitions#events">Definitions</a> for more.',
    'Camera Diagnostics Webhook: "r2" version updated. ECU identifiers are now provided. Multi-frame CAN responses are supported. See <a href="http://portal.partner.owlcam.com/events-api#operations-tag-diagnostics">Webhooks</a> and <a href="http://portal.partner.owlcam.com/definitions#events">Definitions</a> for more.',
    'XirgocamAPI: Fleet managers can now use the Events endpoints to retrieve device events. These endpoints replace the existing Clips endpoints. See <a href="http://portal.partner.owlcam.com/xirgocam-api#operations-tag-events">XirgocamAPI</a> for more.',
    'XirgocamAPI: Fleet managers can now received ECU Identifiers and multi-frame response DTCs via the Diagnostics endpoint. See <a href="http://portal.partner.owlcam.com/xirgocam-api#operations-tag-diagnostics">XirgocamAPI</a> for more.'
];

export const R2_1_11_22_CompatibilityIssues = [

];

export const R2_1_11_22_Bugfixes = [ 
    "State webhook messages now exclude CAN values if they aren't present. Previously they were included with -1 values.",
    "Screen stays off in guard mode when a live view session is initiated.",
    "Camera mountHeight setting is now applied correctly.",
    "OK Presto \"Police\" clips had an overlay of ~10s. This has been removed.",
    "The display no longer shows the guard mode timer if a clip is in progress.",
    "Inside media is uploaded for movement and red alert events even if inside camera is turned off."
];

export const R2_1_11_22_KnownIssues = [
    "ADAS features are disabled during live view sessions.",
    "Audio settings may take up to 30 seconds to take effect.",
    "Occasional on screen video lag.",
    "Glassbreak events temporarily freeze display and media during clip.",
    "Clips generated using tap to create clip always show up as \"marker\" when fetched using the legacy Clips API.",
    "Devices not mounted in a vehicle may trigger aggressive driving events on startup.",
    "OK Presto Police clips upload two 5 minute clips overlapping by 2.5 minutes instead of two 2.5 minute clips with no overlap.",
    "Clips may not show up in the consumer app until all media is uploaded."
];
