export const XirgocamTableContentListR1 = [
  {
    id: "#operations-tag-camera",
    name: "Camera"
  },
  {
    id: "#operations-tag-camera_activation",
    name: "Camera Activation"
  },
  {
    id: "#operations-tag-camera_settings",
    name: "Camera Settings"
  },
  {
    id: "#operations-tag-camera_telemetry",
    name: "Camera Telemetry"
  },
  {
    id: "#operations-tag-camera_vehicle",
    name: "Camera Vehicle"
  },
  {
    id: "#operations-tag-clips",
    name: "Clips"
  },
  {
    id: "#operations-tag-messaging",
    name: "Messaging"
  },
  {
    id: "#model-Camera",
    name: "Schemas"
  }
]