export const R2_7_19_22_ReleaseSummary = [
    "Release Version: 32.7.1.  This is a followup to R32.7 to address an issue with storage space causing devices to have sporadic reporting."
  ];
  
  export const R2_7_19_22_Features = [];
  
  export const R2_7_19_22_CompatibilityIssues = [];
  
  export const R2_7_19_22_Improvements = [];
  
  export const R2_7_19_22_Bugfixes = [
    "Fixed an issue that causes devices to run out of storage space, thus creating sporadic reporting. [XIR2-741]",
  ];
  
  export const R2_7_19_22_KnownIssues = [
    "ADAS features will be disabled during live view sessions. [XIR-810]",
    "Audio setting takes some time to take effect (< 30 seconds). [XIR-811]",
    "Occasionally video on screen has slight lag. [XIR-541]",
    "Devices not mounted in a vehicle may trigger aggressive driving events on startup. [XIR-589]",
    "Occasionally, red 'Recording' dot on camera display will be there indefinitely after an 'OK Presto' clip is made. [XIR-582]",
    "Occasionally, sliding 'up' on the screen will not turn on the inside camera and the 'outside' camera view will be stretched poorly.  Fix is to swipe down, then up again. [XIR-530]",
    "When the device has no GPS position, it will report the last known accurate location for events until it receives a new location. [XIR-690]",
    "Events and Telemetry/State use two different sources for position, leading to inconsistencies in reported location. [XIR-707]",
    "Extremely rarely, the camera will not properly enter guard mode at the end of the timer.  Bumps, trips, or other events will reset this and normal function will resume. [XIR-700]",
    "A small subset of cameras cannot update firmware on serial communication chip; they will need to be replaced to use OBD-PRO Cable upon its delivery. [XIR-730]",
    "GPS position will tend to drift depending on GPS accuracy while device is stationary. [XIR-473]",
    "In certain cases, display will not turn back on when voltage goes over 13V when displayRequiresIgnition is enabled. [XIR2-884]",
  ];