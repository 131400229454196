import React from "react";

import CameraAPI from "./CameraAPI";
// set to true to render the content and save locally
const STATIC_RELEASE = true;

function getStaticModelForRelease(release) {

  switch(release){
    //currently the Camera API is the same for all versions, 
    //but it could differ in the future
    case "R1":
      return CameraAPI("R1");
    case "R2":
      return CameraAPI("R2");
    case "Roadmap":
      return CameraAPI("Roadmap");
    default:
      return CameraAPI("R1");
  }

}

class CameraAPIServerLoad extends React.Component {
  state = { content: null };

  componentDidMount() {
    if (!STATIC_RELEASE) {
      this.downloadContent(this.props.version);
    }
  }

  componentDidUpdate() {
    if (!STATIC_RELEASE) {
      this.downloadContent(this.props.version);
    }
  }

  render() {
    if (STATIC_RELEASE) {
      return getStaticModelForRelease(this.props.version);
    }
    return (
      <div id="main-camera-api" className="container-fluid">
        <div className="flex-xl-nowrap row">
          <div className="col-xl-2 col-md-3 col-12 d-flex flex-column sidenav sidenavitem">
          </div>
          <main
            className="col-12 col-md-9 col-xl-10 py-md-3 pl-md-5 bd-content"
            role="main">
            {this.state.content || "Loading... please wait"}
          </main>
        </div>
      </div>
    );

  }
}

export default CameraAPIServerLoad;
