export function isDemoOrDevelopmentEnvironment() {
  console.log("utils/environmentControl - isDemoOrDevelopmentEnvironment? :: process.env.REACT_APP_DEV_MODE -> ", process.env.REACT_APP_DEV_MODE);
  if (process.env.REACT_APP_DEV_MODE !== "production") {
    return true;
  }
  return false;
}

export function isProduction() {
  console.log("utils/environmentControl - isProduction? :: process.env.NODE_ENV -> ", process.env.NODE_ENV);
  if (process.env.NODE_ENV === "production") {
    return true;
  }
  return false;
}