export const R2_12_01_23_ReleaseSummary = [
  "Release Version: 33.0 PRODUCTION 1699003x33",
  "Summary: This is a major release that enables support for the OBD-PRO hardware.",
  "No firmware versions are deprecated with this release."
];

export const R2_12_01_23_Features = [
  "OBD-PRO Cable Support [XIR2-997] [XIR2-1033] [XIR2-1511] [XIR2-1498] [XIR2-107].",
  "More reliable Light-Duty Vehicle support using the VBus expertise of the XT24/XT25 family.",
  "Safer and more robust support of modern vehicles.",
  "Heavy Duty Vehicle (24V, J1939)",
  "Basic PID support (VIN, Speed, RPM, Fuel Level, Odometer (if available), DTCs)."
];

export const R2_12_01_23_CompatibilityIssues = [];

export const R2_12_01_23_Improvements = [
  "A more robust OTA process for subsystems (cables, peripherals, etc) has been implemented. [XIR2-1430][XIR2-1427][XIR2-1347]."
];

export const R2_12_01_23_IssuesAddressed = [
  "Fixed an issue that causes the inside camera to report “offline” while the device is in guard mode [XIR2-1485].",
];

export const R2_12_01_23_Errata = [
  "Devices not mounted in a vehicle may trigger aggressive driving events on startup.",
  "A small subset of devices cannot update firmware on serial communication chip; they will need to be replaced to use OBD-PRO Cable upon its delivery and will have corrupted DTCs if >10 DTCs are present. (No particular firmware)",
  "When Hyperlapse is enabled, /trips/camera API  will report hyperlapseVideo: false until a hyperlapse video is uploaded, then switch to hyperlapseVideo: true",
];

export const R2_12_01_23_KnownIssues = [
  "ADAS/DMS",
  [
    "ADAS features will be disabled during live view sessions. (R32.3) [XIR2-236]",
    "Forward Collision Warning is overly sensitive even on Low settings. (R32.6) [XIR2-857]",
  ],
  "Camera Recording",
  [
    "If devices are without network for several months, they may fill up their storage with media clips and be unable to record new video until all media is uploaded. (All version) [XIR2-1110]",
    "Occasionally, OK Presto voice recognition is disabled when device has recently regained network connection (R32.5) [XIR2-761]",
    "Occasionally, if the camera is live viewed while it is in guard mode, audio will cut out after approximately 20 seconds.  This affects Live View only and does not affect history/event audio. (R32.9) [XIR2-1358]",
  ],
  "Guard Mode",
  [
    "Extremely rarely, the device will not properly enter guard mode at the end of the timer. Bumps, trips, or other events will reset this and normal function will resume. (All versions) [XIR-700]",
    "Extremely rarely, the device will not enter guard mode at the end of the timer if “guard mode ignitionOnDisable” is enabled. (R32.9) [XIR2-1360]",
  ],
  "LCD Display",
  [
    "Occasionally video on display has slight lag. This does not affect recorded video. (All versions) [XIR2-229] [XIR2-944]",
    "Occasionally, sliding 'up' on the display will not turn on the inside camera and the 'outside' camera view will be stretched poorly.  Fix is to swipe down, then up again. (All versions) [XIR2-219]",
    "Occasionally, red 'Recording' dot on LCD display will be there indefinitely after an 'OK Presto' clip is made. (All versions) [XIR2-246]",
    "A slight lag is noticed in switching from Night to Day mode on the LCD display (R32.5) [XIR-578]",
    "When displayRequiresIgnition is enabled, it is possible display will not come on during an Ignition On event (R32.7) [XIR2-884]",
    "Swiping up and down on the display in rapid succession will cause the “Inside Camera” setting to become out of sync with what is reflected in the cloud (All versions) [XIR2-1108]",
    "Occasionally, changing orientation from the LCD Settings menu during a trip will cause the LCD screen to not turn on.  Resolved by rebooting device (hold down button on side for 5 seconds) (R32.9) [XIR2-1322]",
  ],
  "Telemetry",
  [
    "Occasionally, event files will not be uploaded until the device’s next scheduled reboot (Fleet only) (R32.5) [XIR2-924]",
    "History clips will include the current telemetry information instead of the telemetry of the requested timestamp (R32.5) [XIR2-252]",
  ],
  "Watermarking",
  [
    "When changing watermarking settings, the size of the watermark will be very large during any video clip that includes the exact time the setting was changed. (R32.9) [XIR2-1106]",
    "Timestamps on watermarked videos will sometimes jump by 2 seconds at a time. (R32.9) [XIR2-1375]",
  ],
  "Other",
  [
    "Audio setting takes some time to take effect (< 30 seconds). (All versions) [XIR2-224]",
    "On vehicles with batteries in poor condition, the device will occasionally reboot upon vehicle start when using an OBD-PRO cable. [XIR2-1573]"
  ],
];
