import React from "react";

// SWAGGER
import SwaggerUI from "swagger-ui-react";
// import "swagger-ui-react/swagger-ui.css";

// COMPONENT
import TableContentList from "../app/components/TableContent/TableContentList";
import TitleBlock from "../app/components/Titles/TitleBlock";
// import { AppConfiguration } from "../configuration/App.configuration";

//SWAGGER 
import R1SwaggerSpec from "../versionContent/r1/xirgocam.swagger.json";
import R2SwaggerSpec from "../versionContent/r2/xirgocam.swagger.json";
import RoadmapSwaggerSpec from "../versionContent/roadmap/xirgocam.swagger.json";

class XirgocamAPI extends React.Component {
  state = { url: null, version: this.props.version };

  async updateComponent(version) {
    const spec = getSwaggerForVersion(version);
    this.setState({ spec, version })
  }

  async componentDidMount() {
    this.updateComponent(this.props.version);
  }

  async componentDidUpdate() {
    const { version } = this.props
    if (this.state.version !== version) {
      this.updateComponent(version);
    }
  }

  render() {
    return (
      <div className="row">
        <TableContentList version={this.props.version} name={"xirgocam"} />
        <main className="col-12 col-md-9 col-xl-10 py-md-3 pl-md-5 bd-content clearcolfmt" role="main">
          <TitleBlock
            title="Xirgocam API"
            release={this.state.version}
          />
          <SwaggerUI
            spec={this.state.spec}
            docExpansion="list"
            className="padding-16"
          />
        </main>
      </div>
    );
  }
}

function getSwaggerForVersion(version){
  switch(version){
    case "R1":
      return R1SwaggerSpec;
    case "R2":
      return R2SwaggerSpec;
    case "Roadmap":
      return RoadmapSwaggerSpec;
    default:
      return R1SwaggerSpec;
  }
}

export default XirgocamAPI;
