import React from 'react'

const TableContentSection = ({ headerId, headerName, contentList }) => {
  return (
    <React.Fragment>
      <a href={headerId}>{headerName}</a>
      {contentList.length > 0 &&
        <ul>
          {contentList.map(item => {
            return (
              <li key={item.id}>
                <a href={item.id} >{item.name}</a>
              </li>
            )
          })}
        </ul>
      }
    </React.Fragment>
  )
}

export default TableContentSection;

