export const R2_11_23_21_Summary = 'This release includes the new /system and /settings webhooks, improved aggressive driving detection, more robust device reporting configuration, new endpoints to check device connectivity, and improvements or additions to existing features.';

export const R2_11_23_21_Features = [
    'Camera System Webhook: "r2" version added. Fleet managers can now receive updates for camera system events. See <a href="https://portal.partner.owlcam.com/events-api#operations-tag-system">Webhooks</a> and <a href="https://portal.partner.owlcam.com/definitions#system">Definitions</a> for more.',
    'Camera Setting Webhook: "r2" version added. Fleet managers can now receive updates for camera settings update events. See <a href="https://portal.partner.owlcam.com/events-api#operations-tag-settings">Webhooks</a> for more.',
    'SettingsAPI: Fleet managers can now configure Xirgocam message reporting on a per-feature basis. See <a href="https://portal.partner.owlcam.com/definitions#eventSettings">Definitions</a> for more.',
    'XirgocamAPI: Fleet managers can now use the new device connection endpoint to check if a device is online. See <a href="https://portal.partner.owlcam.com/xirgocam-api#operations-tag-camera_connection">XirgocamAPI</a> for more.',
    'Aggressive Driving: Improvements to the aggressive driving algorithm have been made. See <a href="https://portal.partner.owlcam.com/definitions#aggressiveDrivingSettings">Definitions</a> for more.'
]

export const R2_11_23_21_CompatibilityIssues = [

]

export const R2_11_23_21_Bugfixes = [
    "Settings for audio will now be respected during live view sessions."
]

export const R2_11_23_21_KnownIssues = [
    "ADAS features are disabled during live view sessions."
];
