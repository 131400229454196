export const R2_6_28_22_ReleaseSummary = [
  "Release Version: 32.7. ",
  "This release is a major feature release for the Xirgocam system, introducing the Driver Monitoring System (DMS) Beta for partner introduction and testing.  It also introduces custom startup screen logos and an administrative API to allow partners better control of their cameras.  It includes numerous bugfixes and stability improvements."
];

export const R2_6_28_22_Features = [
  "DMS Beta - Fatigue, Distraction, and Cellphone",
  "Startup Screen Branding",
  "Administrative API - Remote Commands (Check for Update, Reboot, Settings Reset, Factory Default Reset)",
  "New Setting - Device Screen on only if Ignition is On",
];

export const R2_6_28_22_CompatibilityIssues = [];

export const R2_6_28_22_Improvements = [
  "Removed ADAS Vehicle Detection and Distance on LCD Screen",
  "Updated ADAS Library [XIR-692]",
  "Improved ADAS Sensitivity [XIR-772, XIR-744, XIR-745, XIR-746]",
  "Improved Day/Night Sensing to turn on IR LEDs [XIR-760]",
  "Improved testing support for RapidSOS (Consumer) [XIR-758]",
  "Improved camera connection during periods of DNS outage [XIR-800]",
  "Improved trip end detection in ECO mode vehicles (will now keep trip live for 3 minutes instead of 1 minute) [XIR-879]",
  "Improved time between event generation and event transmission to partner in the case where multiple events are generated. [XIR-677, XIR-893]",
  "Improved CAN communication by preventing communication until the Cable and Camera have proper (compatible) versions. [XIR-729]",
  "Improved error logging (Xirgo internal only) [XIR-637]",
];

export const R2_6_28_22_Bugfixes = [
  "Device now restarts when resolution setting changes, allowing setting to take effect immediately [XIR-816]",
  "Devices can now process up to 100 DTCs [XIR-625]",
  "Fixed an issue causing vehicle bus communication to no longer work after the camera exited guard mode [XIR-887]",
  "Fixed an issue that would cause Crash event videos to begin 3 seconds after the crash [XIR-681]",
  "Fixed an issue that would cause OK Presto event videos to begin 3 seconds after the OK Presto [XIR-681]",
  "Fixed an issue which caused some 8890 units to have limited storage capacity [XIR-836]",
  "Fixed an issue where settings were not fully reset after an inactivation until reboot [XIR-693]",
  "Fixed an issue with history event media missing from /clip endpoints [XIR-758]",
  "Fixed duplicate event generation when glassbreaks are trigged [XIR-761, XIR-903]",
  "Fixed issue where brightness setting was reset when device was bumped [XIR-852]",
  "Fixed an issue that could cause negative odometer values [XIR-853]",
];

export const R2_6_28_22_KnownIssues = [
  "ADAS features will be disabled during live view sessions. [XIR-810]",
  "Audio setting takes some time to take effect (< 30 seconds). [XIR-811]",
  "Occasionally video on screen has slight lag. [XIR-541]",
  "Devices not mounted in a vehicle may trigger aggressive driving events on startup. [XIR-589]",
  "Occasionally, red 'Recording' dot on camera display will be there indefinitely after an 'OK Presto' clip is made. [XIR-582]",
  "Occasionally, sliding 'up' on the screen will not turn on the inside camera and the 'outside' camera view will be stretched poorly.  Fix is to swipe down, then up again. [XIR-530]",
  "Events and Telemetry/State use two different sources for position, leading to inconsistencies in reported location. [XIR-707]",
  "Extremely rarely, the camera will not properly enter guard mode at the end of the timer.  Bumps, trips, or other events will reset this and normal function will resume. [XIR-700]",
  "A small subset of cameras cannot update firmware on serial communication chip; they will need to be replaced to use OBD-PRO Cable upon its delivery. [XIR-730]",
  "GPS position will tend to drift depending on GPS accuracy while device is stationary. [XIR-473]",
  "On some 8890 units which experienced limited storage capacity, users may experience inaccurate journal and/or failed History requests for the first hour after updating firmware to 32.7 while media is moved.  All media will be restored and available.  This only happens one time on initial boot up after upgrade.",
  "A slight lag may be noticed in switching from Night to Day mode on the LCD screen."
];