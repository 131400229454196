import React from 'react';

export default function Footer() {
  return (
    <footer className='footer'>
      <div className='container'>
        <span className='text-muted'>Xirgocam Inc © 2022</span>
      </div>
    </footer>
  );
}
