import React from "react";

export default function XirgocamLogo(props) {
  return (
    <svg
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384.27 55.89"
      style={{ margin: 3 }}>

      <g fill={props.fill} fillRule="evenodd">
        <g>
          <path d="M56.9,18.43a5.06,5.06,0,0,1-1.4,4q-1.05,1-5.26,2.45l-9.93,3.54c2.52.85,5.06,1.7,7.64,2.54a37.84,37.84,0,0,1,6.85,2.88q2.1,1.36,2.1,4.67v8.39c0,.22-.23.34-.71.38H51.47c-.41,0-.65-.13-.71-.38V40.72A9.66,9.66,0,0,0,50.33,37q-.78-1.52-4.38-2.84L34.88,30.28,24,34.11q-3.32,1.23-4.28,2.79a9.27,9.27,0,0,0-.52,3.82v6.19a.72.72,0,0,1-.71.38H13.71c-.47,0-.7-.16-.7-.38V38.52q0-3.3,2.11-4.62a43.07,43.07,0,0,1,7-2.92l7.46-2.55-9.92-3.54c-2.81-1-4.56-1.79-5.27-2.45a5.09,5.09,0,0,1-1.4-4V9.94c0-.22.23-.35.7-.38h4.74a.72.72,0,0,1,.71.38v6.18a10.07,10.07,0,0,0,.44,3.82q.87,1.5,4.47,2.78L35,26.54l10.88-3.82q3.54-1.27,4.4-2.83a8.74,8.74,0,0,0,.52-3.77V9.94c.06-.25.3-.38.71-.38h4.72c.48,0,.71.16.71.38Z" />
          <path d="M67.44,9.94v37c-.06.25-.3.38-.71.38H62c-.46,0-.7-.13-.7-.38v-37c0-.22.24-.35.7-.38h4.74C67.2,9.56,67.44,9.69,67.44,9.94Z" />
          <path d="M115.72,46.91c-.06.25-.3.38-.71.38h-4.72c-.48,0-.72-.13-.72-.38V37.05a9,9,0,0,0-1.12-3.31,10.59,10.59,0,0,0-6.85-4.86Q95.09,27.14,78,22.71v24.2c0,.25-.23.38-.7.38H72.53c-.47,0-.7-.13-.7-.38v-37c0-.25.29-.38.7-.38H96.14a34.15,34.15,0,0,1,8.69,1.13q8.52,2.26,8.52,7.88V21q0,2.68-6.41,5.75,8.78,4.25,8.78,9.67ZM107.2,21V18.57q0-5.38-11.11-5.8H78v6.32l22.91,6c1.22-.51,2.25-1,3.12-1.42C106.14,22.59,107.2,21.71,107.2,21Z" />
          <path d="M164,46.91c-.06.25-.29.38-.7.38h-4.57c-.47,0-.76-.13-.88-.38V45.68a37.3,37.3,0,0,1-11.06,1.61H137q-10.53-.1-14.75-4.3a7.16,7.16,0,0,1-2.1-4.19V18.05a7.17,7.17,0,0,1,2.19-4.24q4.31-4.24,15-4.25h21.25c.47,0,.7.13.7.38v2.45c0,.19-.23.32-.7.38H137.31q-10.26,0-11.06,5.8V38.28q.8,5.8,11.06,5.8h9.48q10.28,0,11.06-5.8v-12H139.24c-.46,0-.7-.12-.7-.38V23.47c0-.19.24-.31.7-.37H163.3c.47,0,.7.12.7.37Z" />
          <path d="M212.28,38.8A7.17,7.17,0,0,1,210.09,43q-4.31,4.24-15,4.25h-9.49q-10.71,0-15-4.25a7.17,7.17,0,0,1-2.19-4.24V18.05q0-5.57,8.51-7.59a37.74,37.74,0,0,1,8.69-.9h9.49a37.74,37.74,0,0,1,8.69.9q8.5,2,8.51,7.59Zm-6.14-.52V18.57q-.88-5.81-11.12-5.8h-9.46q-10.25,0-11,5.8V38.28q.79,5.8,11,5.8H195Q205.27,44.08,206.14,38.28Z" />
          <path d="M251.78,46.91c0,.22-.23.34-.7.38H234.23q-10.89,0-15.37-4a6.52,6.52,0,0,1-2.19-4V17.58a6.52,6.52,0,0,1,2.19-4q4.48-4,15.37-4h16.85c.47,0,.7.16.7.38v2.45c0,.19-.23.32-.7.38H234.23q-10.54,0-11.42,5.8V38.28q.88,5.8,11.42,5.8h16.85c.47,0,.7.16.7.38Z" />
          <path d="M300.07,46.91c-.06.25-.3.38-.71.38h-4.74c-.47,0-.7-.16-.7-.38V22.65L270.3,28.89a11,11,0,0,0-6.75,4.43,6.81,6.81,0,0,0-1.23,3.59v10a.7.7,0,0,1-.7.38h-4.74c-.47,0-.71-.16-.71-.38V18.33q0-4,4.79-6.36t12.42-2.41h9.39q7.73,0,12.51,2.36t4.79,6.41Zm-6.15-27.82q-.8-6.33-11.06-6.32h-9.48q-10.28,0-11.06,5.8v9.62a24.31,24.31,0,0,1,5.26-2.27Q267.85,25.88,293.92,19.09Z" />
          <path d="M370.38,46.91c0,.22-.24.34-.71.38H365a.7.7,0,0,1-.71-.38v-29L342.82,40.4a7.34,7.34,0,0,1-5.36,2,13.13,13.13,0,0,1-2-.24,6.39,6.39,0,0,1-3.51-1.79L310.6,17.91v29a.7.7,0,0,1-.7.38h-4.74c-.47,0-.71-.16-.71-.38v-37c0-.25.24-.38.72-.38h3.91a2.76,2.76,0,0,1,1.69.73l26.65,27.8L364.09,10.3a2.78,2.78,0,0,1,1.77-.74h3.81c.47,0,.71.16.71.38Z" />
        </g>
        <g>
          <path d="M372.81,11.64v-4H371V6.83h4.72v.82H374v4Z" />
          <path d="M376.52,11.64V6.83h1.79l1.08,3.28,1.07-3.28h1.8v4.81h-1.11V7.85L380,11.64h-1.15l-1.17-3.79v3.79Z" />
        </g>
      </g>
    </svg>
  );
}
