// REACT
import React from 'react';

// COMPONENTS
import DefinitionSection from '../app/components/Definitions/DefinitionSection';
import TitleBlock from '../app/components/Titles/TitleBlock';
import TableContentListWithSections from '../app/components/TableContent/TableContentListWithSections';

//VERSIONED DEFINITIONS
import R1Definitions from '../versionContent/r1/definitions.json';
import R2Definitions from '../versionContent/r2/definitions.json';
import RoadmapDefinitions from '../versionContent/roadmap/definitions.json';
import SettingsRelease1Definitions from '../versionContent/settings-release1/definitions.json';
import AdminRelease1Definitions from '../versionContent/admin-release1/definitions.json';

//TABLE CONTENT
import { DefinitionTableContentR1 } from '../versionContent/r1/definitions.tableContent';
import { DefinitionTableContentR2 } from '../versionContent/r2/definitions.tableContent';
import { DefinitionTableContentRoadmap } from '../versionContent/roadmap/definitions.tableContent';
import { DefinitionTableContentSettingsRelease1 } from '../versionContent/settings-release1/definitions.tableContent';
import { DefinitionTableContentAdminRelease1 } from '../versionContent/admin-release1/definitions.tableContent';

const Definitions = ({ version }) => {
  let VersionedDefinitions = getVersionedDefinitions(version);
  let VersionedTableContent = getVersionedTableContent(version);
  return (
    <div className="container-fluid">
      <div className="flex-xl-nowrap row">
        <TableContentListWithSections contentSections={VersionedTableContent} />
        <main
          className="col-12 col-md-9 col-xl-10 py-md-3 pl-md-5 bd-content"
          role="main"
          id="main-definitions">

          <TitleBlock
            title={'Definitions'}
            release={version} />

          <p className="bd-lead">
            The following reference is a detailed explanation of how the various enumerations and definitions are used by Xirgocam. This information is in addition to the schema to better understand how and when to use specific values.
          </p>
          <hr />
          {VersionedDefinitions.allDefinitions.map((def, i) => {
            return (
              <React.Fragment>
                <h2 id={def.id} className="model-name">{def.header}</h2>
                <p>{
                  def.description.split('\n').map((i) => {
                    return <p>{i}</p>
                  })
                }</p>
                {def.subDefinitions.map((innerDef, i) => {
                  return(
                    <DefinitionSection
                    id={innerDef.id}
                    codeName={innerDef.codeName}
                    title={innerDef.title}
                    body={innerDef.body}
                    definitions={innerDef.definitions}
                  />
                  );
                })}
              </React.Fragment>
            );
          })}
          </main>
        </div>
      </div>
  )
}

function getVersionedDefinitions(version){
  switch(version){
    case "R1":
      return R1Definitions;
    case "R2":
      return R2Definitions;
    case "Roadmap":
      return RoadmapDefinitions;
    case "Settings Release 1":
      return SettingsRelease1Definitions;
    case "Admin Release 1":
      return AdminRelease1Definitions;
    default:
      return R1Definitions;
  }
}

function getVersionedTableContent(version){
  switch(version){
    case "R1":
      return DefinitionTableContentR1;
    case "R2":
      return DefinitionTableContentR2;
    case "Roadmap":
      return DefinitionTableContentRoadmap;
    case "Settings Release 1":
      return DefinitionTableContentSettingsRelease1;
    case "Admin Release 1":
      return DefinitionTableContentAdminRelease1;
    default:
      return DefinitionTableContentR1;
  }
}

export default Definitions;