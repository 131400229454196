import R1CameraAPI from '../versionContent/r1/CameraAPI';
import R2CameraAPI from '../versionContent/r2/CameraAPI';
import RoadmapCameraAPI from '../versionContent/roadmap/CameraAPI';

export default function CameraAPI(release) {
  
  switch(release){
    case "R1":
      return R1CameraAPI();
    case "R2":
      return R2CameraAPI();
    case "Roadmap":
      return RoadmapCameraAPI();
    default:
      return R1CameraAPI();
  }
  
  
}
