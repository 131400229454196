import { XirgocamTableContentListR1 } from "../versionContent/r1/xirgocam.tableContent"
import { EventTableContentListR1 } from "../versionContent/r1/events.tableContent"
import { XirgocamTableContentListRoadmap } from "../versionContent/roadmap/xirgocam.tableContent"
import { XirgocamTableContentListR2 } from "../versionContent/r2/xirgocam.tableContent";
import { EventTableContentRoadmap } from "../versionContent/roadmap/events.tableContent";
import { EventTableContentR2 } from "../versionContent/r2/events.tableContent";
import { SettingsTableContentRoadmap } from '../versionContent/roadmap/settings.tableContent';
import { SettingsTableContentSettingsRelease1 } from '../versionContent/settings-release1/settings.tableContent';
import { AdminTableContentRelease1 } from '../versionContent/admin-release1/admin.tableContent';
import { AdminTableContentRoadmap } from '../versionContent/roadmap/admin.tableContent' ;

export function tableContentSelector(version, name) {
  if (version === "R1") {
    switch(name) {
      case 'xirgocam': 
        return XirgocamTableContentListR1; 
      case 'events': 
        return EventTableContentListR1;
      default: 
        return XirgocamTableContentListR1;
    }
  }
  if (version === "Roadmap") {
    switch(name) {
      case 'xirgocam': 
        return XirgocamTableContentListRoadmap; 
      case 'events': 
        return EventTableContentRoadmap;
      case 'settings':
        return SettingsTableContentRoadmap; 
      case 'admin':
        return AdminTableContentRoadmap;
      default: 
        return XirgocamTableContentListRoadmap;
    }
  }
  if (version === "R2") {
    switch(name) {
      case 'xirgocam': 
        return XirgocamTableContentListR2; 
      case 'events': 
        return EventTableContentR2;
      default: 
        return XirgocamTableContentListR2;
    }
  }
  if (version === "Settings Release 1") {
    switch(name) {
      case 'settings':
        return SettingsTableContentSettingsRelease1;
      default: 
        return SettingsTableContentSettingsRelease1;
    }
  }
  if (version === "Admin Release 1") {
    switch(name) {
      case 'admin':
        return AdminTableContentRelease1;
      default: 
        return AdminTableContentRelease1;
    }
  }
  return [];
}